<template>
  <o-form :title="$t('core.role')"
          :url="'/api/system/roles/' + $route.params.id + '?include=permissions,routePermissions'"
          class="ortic-form-horizontal">
    <template slot-scope="props">
      <b-row>
        <b-col>
          <o-input :label="$t('core.name')" v-model="props.item.name"/>
          <o-form-group :label="$t('core.permissions')">
            <div class="w-100">
              <o-typeahead-control class="mb-3" url="/api/system/permissions"
                                   v-on:value-selected="item => props.item.permissions.push(item)"></o-typeahead-control>
              <ul class="list-group">
                <li class="list-group-item"
                    v-for="(permission, permissionKey) in props.item.permissions">
                  {{permission.name}}

                  <span @click="props.item.permissions.splice(permissionKey, 1)" class="float-right">
                                    <fa icon="times"/>
                                </span>
                </li>
              </ul>
            </div>
          </o-form-group>
          <o-form-group :label="$t('core.route_permissions')">
            <div class="w-100">
              <o-typeahead-control class="mb-3" url="/api/system/route_permissions"
                                   v-on:value-selected="item => props.item.routePermissions.push(item)">
                <template slot="item" slot-scope="props">
                  <span :title="props.item.action">{{props.item.method}}: {{props.item.uri}}</span>
                </template>
              </o-typeahead-control>
              <ul class="list-group">
                <li class="list-group-item"
                    v-for="(routePermission, routePermissionKey) in props.item.routePermissions">
                  <span :title="routePermission.action">{{routePermission.method}}: {{routePermission.uri}}</span>

                  <span @click="props.item.routePermissions.splice(routePermissionKey, 1)"
                        class="float-right">
                                    <fa icon="times"/>
                                </span>
                </li>
              </ul>
            </div>
          </o-form-group>
        </b-col>
        <b-col>

        </b-col>
      </b-row>
    </template>
  </o-form>
</template>

<script>
  export default {
    data: () => ({
      search: {
        permissionName: '',
        result: []
      }
    }),

    mounted () {
    },

    methods: {
      permissionSelected (data) {
        window.alert(JSON.stringify(data))
      }
    }
  }
</script>
