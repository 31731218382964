<template>
  <div class="LocationPicker">
    <div class="LocationPicker__map" ref="map"></div>
    <input type="text" class="LocationPicker__autocomplete" ref="input"/>
    <info-window class="LocationPicker__info-window" ref="info"></info-window>
  </div>
</template>


<script>

  import {LocationPickerEventBus} from './LocationPickerEventBus'
  import InfoWindow from './InfoWindow.vue'

  export default {
    props: {
      value: {},
      zoom: {
        default: 5
      }
    },
    data() {
      return {
        geocoder: null,
        map: null,
        marker: null,
        infoWindow: null,
        autocomplete: null
      }
    },
    components: {InfoWindow},
    mounted() {
      if (window.locationPickerInitialized) {
        this.init()
      }
      else {
        LocationPickerEventBus.$on('location-picker-init', function () {
          this.init()
        })
      }
    },
    watch: {
      value(newVal, oldVal) {
        if (newVal !== oldVal) {
          var parts = newVal.split(',')
          if (parts && parts.length == 2) {
            var location = new google.maps.LatLng(parseFloat(parts[0]), parseFloat(parts[1]))

            google.maps.event.clearInstanceListeners(this.autocomplete)

            this.map.panTo(location)
            this.marker.setPosition(location)
            this.geocodeAddress(location)

            this.autocomplete.addListener('place_changed', this.moveMarker)
          }
        }
      }
    },
    methods: {
      init() {
        this.geocoder = new google.maps.Geocoder()
        this.map = new google.maps.Map(this.$refs.map, Object.assign({
          center: {lat: 0, lng: 0},
          zoom: this.zoom,
          disableDefaultUI: true
        }, {}))
        this.marker = new google.maps.Marker(Object.assign({
          map: this.map,
          position: this.map.getCenter(),
          draggable: true
        }, {}))
        this.infoWindow = new google.maps.InfoWindow(Object.assign({
          content: this.$refs.info.$el
        }, {}))
        this.autocomplete = new google.maps.places.Autocomplete(this.$refs.input, Object.assign({
          types: ['geocode']
        }, {}))
        this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.$refs.input)
        // events
        google.maps.event.addListenerOnce(this.map, 'idle', this.openInfoWindow)
        this.marker.addListener('dragstart', this.closeInfoWindow)
        this.marker.addListener('dragend', this.geocodeLocation)
        this.autocomplete.addListener('place_changed', this.moveMarker)
        this.map.addListener('click', this.mapClick)
      },
      openInfoWindow() {
        this.infoWindow.open(this.map, this.marker)
      },
      closeInfoWindow() {
        this.infoWindow.close()
      },
      mapClick (e) {
        this.map.panTo(e.latLng)
        this.geocodeAddress(e.latLng)
        this.$emit('input', e.latLng.lat() + ',' + e.latLng.lng())
      },
      geocodeAddress(latLng) {
        this.geocoder.geocode({'latLng': latLng}, (response) => {
          if (response && response.length > 0) {
            this.$emit('address_selected', response[0].formatted_address)
            this.$refs.info.showAddress(response[0])
          } else {
            this.$refs.info.showError()
          }
          this.openInfoWindow()
        })
      },
      geocodeLocation(e) {
        this.map.panTo(e.latLng)
        this.$refs.input.value = ''
        this.geocodeAddress(e.latLng)
        this.$emit('input', e.latLng.lat() + ',' + e.latLng.lng())
      },
      moveMarker() {
        var place = this.autocomplete.getPlace()
        var location = place.geometry && place.geometry.location

        if (location) {
          this.$emit('input', location.lat() + ',' + location.lng())
          this.map.panTo(location)
          this.marker.setPosition(location)
          this.$emit('coordinates_updated', {lat: location.lat(), lng: location.lng()})
          this.$emit('address_selected', place.formatted_address)
          this.$refs.info.showAddress(place)
        }
      }
    }
  }
</script>

<style>
  .LocationPicker,
  .LocationPicker__map {
    height: 100%;
  }

  .LocationPicker__autocomplete {
    padding: 7px 14px;
    margin: 10px;
    width: 30%;
    min-width: 300px;
    text-overflow: ellipsis;
    border: 0;
    border-radius: 2px 0 0 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }

  .LocationPicker > .LocationPicker__autocomplete,
  .LocationPicker > .LocationPicker__info-window {
    display: none;
  }
</style>
