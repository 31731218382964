<template>
  <div class="ortic-typeahead-control">
    <input :placeholder="placeholder"
           class="form-control"
           v-model="query"
           autocomplete="off"
           @keydown.down="down"
           @keydown.up="up"
           @keydown.enter.prevent="hit"
           @keydown.esc="reset"
           @blur="reset"
           @input="update">

    <ul v-show="hasItems">
      <!-- for vue@1.0 use: ($item, item) -->
      <li v-for="(item, $item) in items" :class="activeClass($item)" @mousedown="hit"
          @mousemove="setActive($item)">
        <slot name="item" :item="item">
          <span v-text="item.name"></span>
        </slot>
      </li>
    </ul>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'o-typeahead-control',
    props: {
      placeholder: {
        default: null
      },
      url: {
        type: String,
        required: true
      },
      minChars: {
        type: Number,
        default: 3
      },
      limit: {
        type: Number,
        default: 8
      },
      selectFirst: {
        type: Boolean,
        default: false
      },
      queryParamName: {
        type: String,
        default: 'q'
      }
    },

    data: () => ({
      localValue: '',
      items: [],
      query: '',
      current: -1,
      loading: false
    }),

    mounted() {
      this.localValue = this.value
    },

    components: {},

    computed: {
      hasItems() {
        return this.items.length > 0
      },

      isEmpty() {
        return !this.query
      },

      isDirty() {
        return !!this.query
      }
    },

    watch: {
      value(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.localValue = newVal
        }
      },
      localValue(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('input', newVal)
        }
      }
    },

    methods: {
      update() {
        this.cancel()

        if (!this.query) {
          return this.reset()
        }

        if (this.minChars && this.query.length < this.minChars) {
          return
        }

        this.loading = true

        this.fetch().then((response) => {
          if (response && this.query) {
            let data = response.data.data
            data = this.prepareResponseData(data)
            this.items = this.limit ? data.slice(0, this.limit) : data
            this.current = -1
            this.loading = false

            if (this.selectFirst) {
              this.down()
            }
          }
        })
      },

      fetch() {
        const url = this.queryParamName
          ? this.url
          : this.url + this.query

        const params = this.queryParamName
          ? Object.assign({[this.queryParamName]: this.query}, this.data)
          : this.data

        let cancel = new Promise((resolve) => this.cancel = resolve)
        let request = axios.get(url, {params})

        return Promise.race([cancel, request])
      },

      cancel() {
        // used to 'cancel' previous searches
      },

      reset() {
        this.items = []
        this.query = ''
        this.loading = false
      },

      setActive(index) {
        this.current = index
      },

      activeClass(index) {
        return {
          active: this.current === index
        }
      },

      hit() {
        if (this.current !== -1) {
          var item = this.items[this.current]
          this.reset()
          this.$emit('value-selected', item)
        }
      },

      up() {
        if (this.current > 0) {
          this.current--
        } else if (this.current === -1) {
          this.current = this.items.length - 1
        } else {
          this.current = -1
        }
      },

      down() {
        if (this.current < this.items.length - 1) {
          this.current++
        } else {
          this.current = -1
        }
      },

      prepareResponseData(data) {
        return data
      }
    }
  }
</script>
