<template>
  <button class="btn btn-secondary" @click.prevent="confirmActive = !confirmActive">
    <template v-if="confirmActive">
      <div class="pop-cover">
        <div class="delete-popover bg-danger">
          Sure?
          <button class="btn btn-primary btn-sm mx-2 px-2 py-0" @click.prevent="executeAction">Yes</button>
          <button class="btn btn-secondary btn-sm px-2 py-0">No</button>
        </div>
      </div>
    </template>

    <fa icon="times"/>
  </button>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'o-delete',
    props: {
      url: {
        type: String,
        default: ''
      },
      method: {
        type: String,
        default: 'delete'
      }
    },

    data: () => ({
      confirmActive: false
    }),

    mounted() {
    },

    components: {},

    methods: {
      executeAction() {
        if (this.url != '') {
          axios
            .request({method: this.method, url: this.url})
            .then((response) => {
              this.confirmActive = false
              this.$emit('action-executed')
            })
            .catch((error) => {
              console.log(error)
            })
        }
        else {
          this.$emit('execute-action')
        }
      }
    }
  }
</script>

<style scoped>
  .pop-cover {
    position: relative;
  }
  .delete-popover {
    position: absolute;
    right: 32px;
    padding: 10px;
    border-radius: 4px;
    width: 180px;
    bottom: -30px;
  }
</style>
