<template>
    <div class="wizard">
        <div class="wizard-nav">
          <div class="v-stepper-root">
            <div :class="[selectedIndex == tabIndex ? 'v-step is-active' : 'v-step']" v-for="(tab, tabIndex) in tabs">
              <label for="" class="label">
                <a :class="{'is-active': selectedIndex == tabIndex}" :href="'step-' + tabIndex"
                   @click.prevent="changePage(tabIndex)">
                  <span class="index">
                    {{tabIndex + 1}}
                  </span>
                  <span class="title d-none d-lg-block"> {{tab}} </span>
                  <span class="divider"></span>
                </a>
              </label>
            </div>
          </div>
          <div class="wizard-body container-fluid" style="margin-top: 30px;">
            <div>
              <slot></slot>
            </div>
        </div>
      </div>
    </div>
</template>

<style scoped>
    /* hack to fix modal */
    .finish-modal-container > div {
        display: block ! important;
    }
</style>

<script>
  import axios from 'axios'

  export default {
    name: 'o-wizard',
    props: {},
    data: () => ({
      tabs: [],
      selectedIndex: 0
    }),
    methods: {
      changePage(index) {
        this.selectedIndex = index

        var self = this
        this.$children.forEach((child, childIndex) => {
          child.$el.style.display = childIndex == self.selectedIndex ? '' : 'none'
        })

        this.$nextTick(() => {
          this.$children[this.selectedIndex].$emit('page-activated')
        })

        this.$emit('page-changed', this.selectedIndex)
      },
      previousPage() {
        this.changePage(this.selectedIndex - 1)
      },
      nextPage() {
        this.changePage(this.selectedIndex + 1)
      },
      isLastPage() {
        return this.tabs.length == this.selectedIndex+1
      }
    },
    watch: {},
    computed: {},
    mounted() {
      var self = this
      this.$children.forEach((child) => {
        if (child.$el.classList.contains('wizard-step')) {
          self.tabs.push(child.title)
        }
      })
      this.changePage(0)

      this.$on('next-page', () => {
        this.changePage(this.selectedIndex + 1)
      })
      this.$on('previous-page', () => {
        this.changePage(this.selectedIndex - 1)
      })
      this.$on('finish', () => {
        this.$parent.$emit('finish')
      })
    }
  }
</script>
