<template>
  <div class="input-group">
    <datepicker v-model="localValue" :disabled="disabled" :open-date="openDate" :disabled-dates="disabledDates" :language="translations" :readonly="readonly" input-class="form-control" ref="datepicker" :typeable="typeable" :format="customFormatter"></datepicker>
    <div class="input-group-append">
      <button class="btn btn-outline-secondary" type="button" @click="$refs.datepicker.showCalendar()">
        <fa icon="calendar"></fa>
      </button>
    </div>
  </div>
</template>

<script>
  import Datepicker from 'vuejs-datepicker'
  import moment from 'moment'
  import store from '~/store'

  export default {
    name: 'o-datepicker-control',
    props: {
      value: {
        default: null
      },
      type: {
        type: String,
        default: 'text'
      },
      typeable: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      disabledDates : {
        type: Object
      },
      openDate : {
        type: Date,
      }
    },

    data: () => ({
      localValue: ''
    }),

    mounted() {
      this.localValue = this.value
    },

    computed: {
      translations() {
        return {
          days: [
            this.$i18n.t('core.sunday_short'),
            this.$i18n.t('core.monday_short'),
            this.$i18n.t('core.tuesday_short'),
            this.$i18n.t('core.wednesday_short'),
            this.$i18n.t('core.thursday_short'),
            this.$i18n.t('core.friday_short'),
            this.$i18n.t('core.saturday_short')
          ],
          language: this.$i18n.t('core.language_name'),
          months: [
            this.$i18n.t('core.january_long'),
            this.$i18n.t('core.february_long'),
            this.$i18n.t('core.march_long'),
            this.$i18n.t('core.april_long'),
            this.$i18n.t('core.may_long'),
            this.$i18n.t('core.june_long'),
            this.$i18n.t('core.july_long'),
            this.$i18n.t('core.august_long'),
            this.$i18n.t('core.september_long'),
            this.$i18n.t('core.october_long'),
            this.$i18n.t('core.november_long'),
            this.$i18n.t('core.december_long')
          ],
          monthsAbbr: [
            this.$i18n.t('core.january_short'),
            this.$i18n.t('core.february_short'),
            this.$i18n.t('core.march_short'),
            this.$i18n.t('core.april_short'),
            this.$i18n.t('core.may_short'),
            this.$i18n.t('core.june_short'),
            this.$i18n.t('core.july_short'),
            this.$i18n.t('core.august_short'),
            this.$i18n.t('core.september_short'),
            this.$i18n.t('core.october_short'),
            this.$i18n.t('core.november_short'),
            this.$i18n.t('core.december_short')
          ],
          rtl: false,
          yearSuffix: '',
          ymd: false
        }
      }
    },

    methods: {
      customFormatter(date) {
        if (date) {
          let user = store.getters['auth/user']
          if(user) {
            return moment(date).format(user.format_date)
          } else {
            return moment(date).format('DD.MM.YYYY')
          }
        }
        return null
      }
    },

    components: {
      Datepicker
    },

    watch: {
      value(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal === null) {
            this.localValue = ''
          } else {
            this.localValue = newVal
          }
        }
      },
      localValue(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('input', newVal ? moment(newVal).format('YYYY-MM-DD') : null)
        }
      }
    }
  }
</script>
