import { render, staticRenderFns } from "./TranslationList.vue?vue&type=template&id=fbee39ac&scoped=true&"
import script from "./TranslationList.vue?vue&type=script&lang=js&"
export * from "./TranslationList.vue?vue&type=script&lang=js&"
import style0 from "./TranslationList.vue?vue&type=style&index=0&id=fbee39ac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbee39ac",
  null
  
)

export default component.exports