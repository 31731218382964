import Vue from 'vue'
import store from '~/store'
import router from '~/router'
import App from '~/components/App'
import {i18n} from '~/plugins/i18n'
import Notifications from 'vue-notification'
import LocationPicker from '~/components/LocationPicker/init.js'

import '~/plugins'
import '~/filters'
import '~/components'
import moment from "moment";

Vue.config.productionTip = false

Vue.use(Notifications)
Vue.use(LocationPicker, {key: 'AIzaSyDfdo4HcUluzig2P_Z7RC8OUJ10OHZuupE'})

Vue.filter('prettyDate',function(created){
  return moment(created).format('DD. MMMM YYYY')
})

new Vue({
  i18n,
  store,
  router,
  ...App
})