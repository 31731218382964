<template>
  <div class="input-group">
    <input v-model="localValue" type="hidden">
    <input v-model="displayValue" readonly :type="type" class="form-control">
    <div class="input-group-append">
      <b-btn variante="secondary" @click="showModal">
        <fa icon="crosshairs"></fa>
      </b-btn>
    </div>

    <b-modal :id="'modal' + uniqueId" :ref="'modal' + uniqueId" :ok-only="true" ok-title="Cancel" title="Pick a value">
      <o-table :url="url" title="">
        <template slot="head">
          <slot name="head"></slot>
        </template>
        <template slot="head-controls">
          &nbsp;
        </template>
        <template slot="body" slot-scope="props">
          <slot name="body" :item="props.item"></slot>
        </template>
        <template slot="body-controls" slot-scope="props">
          <slot name="body-controls" :item="props.item"></slot>
        </template>
      </o-table>
    </b-modal>
  </div>
</template>

<script>
  import OrticTable from '~/components/OrticTable'
  import axios from 'axios'

  export default {
    name: 'o-value-select-modal-control',
    props: {
      value: {
        default: null
      },
      type: {
        type: String,
        default: 'text'
      },
      url: {
        type: String,
        required: true
      },
      urlSingleRow: {
        type: String,
        default: ''
      },
      displayField: {
        type: String,
        default: ''
      },
      valueField: {
        type: String,
        default: 'id'
      }
    },

    data: () => ({
      localValue: '',
      displayValue: '',
      uniqueId: ''
    }),

    mounted () {
      this.uniqueId = Math.random().toString(36).replace(/[^a-z]+/g, '')
      this.localValue = this.value
      if (this.displayField === '') {
        this.displayValue = this.localValue
      } else {
        // load from api
        if (this.value) {
          this.loadDisplayValue(this.value)
        }
      }

      this.$on('select-value', (item) => {
        this.localValue = item[this.valueField]
        if (this.displayField === '') {
          this.displayValue = this.localValue
        }
        else {
          this.displayValue = item[this.displayField]
        }
        this.$refs['modal' + this.uniqueId].hide()
      })
    },

    methods: {
      showModal () {
        this.$refs['modal' + this.uniqueId].show()
      },
      loadDisplayValue(id) {
        var url = this.url + '/' + id
        if (this.urlSingleRow !== '') {
          url = this.urlSingleRow
        }

        var displayField = this.displayField

        axios
          .get(url)
          .then((response) => {
            this.displayValue = response.data[displayField]
          })
          .catch((error) => {
            console.error(error)
            // @TODO what now? report to global error log?
          })
      }
    },

    components: {
      OrticTable
    },

    watch: {
      value(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.localValue = newVal
          if (newVal !== null && newVal !== '') {
            this.loadDisplayValue(newVal)
          }
        }
      },
      localValue(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('input', newVal)
        }
      }
    }
  }
</script>
