<template>
    <div :class="containerClasses">
      <b-input-group>
        <input class="form-control" v-model="value" @keyup.enter="enableFilter">
        <b-input-group-append v-if="showButton">
          <o-button @click="enableFilter">{{$t('core.search')}}</o-button>
        </b-input-group-append>
      </b-input-group>
    </div>
</template>

<script>
  import qs from 'qs'

  export default {
    name: 'o-filter-text',
    props: {
      title: {
        type: String,
        required: true
      },
      column: {
        type: String,
        required: false
      },
      classes: {
        type: String,
        default: ''
      },
      showButton: {
        type: Boolean,
        default: true
      }
    },

    data: () => ({
      containerClasses: '',
      value: ''
    }),

    methods: {
      enableFilter() {
        if(this.value) {
          this.$parent.$emit('enable-filter', {
            column: this.column ? this.column : this.title,
            title: this.title,
            value: this.value + '%',
            displayValue: this.value + '%'
          })
        }
        this.value = ''
      },

      checkUrlFilter() {
        // check url hash to see if we have a filter in the uri
        if (!document.location.search) {
          return
        }
        var params = qs.parse(document.location.search.substr(1))

        if (this.column in params.filter) {
          this.value = params.filter[this.column]
          this.$nextTick(() => {
            this.enableFilter()
          })
        }
      }
    },

    mounted() {
      this.$on('set-classes', (classes) => {
        this.containerClasses = classes
      })
      this.checkUrlFilter()
    }
  }
</script>
