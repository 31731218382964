<template>
    <div>
        <o-button :loading="true"></o-button>
    </div>
</template>

<script>
export default {
    mounted() {
        this.$store.dispatch('auth/saveToken', {
            token: this.oauthToken
        })
        this.$store.dispatch('auth/fetchUser')      
        this.$router.push({name: 'welcome'})
    },
    computed: {
        oauthToken: () => window.config.oauth_token
    }
}
</script>