<template>
  <o-form :title="$t('offer.order')"
          :url="`/api/cart-orders/${$route.params.id}?include=products,user,products.product,wholesaler`"
          class="ortic-form-horizontal">
    <template slot-scope="props">
      <b-row>
        <b-col>
          <strong>{{$t('offer.address')}}</strong><br>
          {{props.item.name}}<br>
          {{props.item.street}}<br>
          <div v-if="props.item.recipient_name">{{props.item.recipient_name}}</div>
          {{props.item.zip_code}} {{props.item.city}}<br>

          <div v-if="props.item.gln">
            GLN: {{props.item.gln}}
          </div>
        </b-col>
        <b-col v-if="props.item.user">
          <strong>{{$t('offer.order_information')}}</strong><br>          
           {{$t('offer.email')}}: {{props.item.user.email}}<br>    
           {{$t('offer.wholesaler')}}: {{props.item.wholesaler.wholesaler}}<br>
           {{$t('offer.date')}}: {{props.item.created_at | formatDate}}<br>
           {{$t('offer.delivery_date')}}: {{props.item.delivery_date | formatDate}}<br>
           <div v-if="props.item.user.gln">
            {{$t('offer.gln')}}: {{props.item.user.gln}}<br>
           </div>
           <div v-if="props.item.user.acc_type">
            {{$t('offer.acc_type')}}: {{props.item.user.acc_type}}<br>
           </div>
           <div v-if="props.item.user.acc_grp">
            {{$t('offer.acc_grp')}}: {{props.item.user.acc_grp}}<br>
           </div>
        </b-col>
        <b-col>   
            {{$t('offer.intermediate_total')}}: CHF {{props.item.product_total | formatNumber}}<br>
            {{$t('offer.discount')}}: {{props.item.discount_percentage*100}}% ({{props.item.discount}})<br>
            <template v-if="props.item.logistics_surcharge">

                {{$t('offer.intermediate_total')}}: CHF {{props.item.product_total - props.item.discount | formatNumber}}<br>
                {{$t('offer.logistics_surcharge')}}: CHF {{props.item.logistics_surcharge | formatNumber}}<br>
                {{$t('offer.order_total')}}: CHF {{props.item.order_total | formatNumber}}<br>
            </template>
            <template v-else>
                {{$t('offer.total')}}: CHF {{props.item.total | formatNumber}}<br>
            </template>
        </b-col>
      </b-row>
    </template> 
    <template slot="child" slot-scope="props">
      <b-tabs>
        <b-tab :title="$t('offer.products')" active>
          <o-card>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                      <th>{{$t('offer.product')}}</th>
                      <th>{{$t('offer.package')}}</th>
                      <th>{{$t('offer.quantity')}}</th>
                      <th>{{$t('offer.efp')}}</th>
                  </tr>
                </thead>
                <tbody>
                    <tr v-for="product in props.item.products" :key="product.id">
                        <td>{{product.product.product}}</td>
                        <td>{{product.product.package}}</td>
                        <td>{{product.quantity}}</td>
                        <td>{{product.efp}}</td>
                    </tr>
                </tbody>
              </table>
            </div>
          </o-card>
        </b-tab>
      </b-tabs>
    </template>
    <template slot="footer-toolbar-right-before" slot-scops="props">
      <o-button variant="primary" :loading="downloadingPdf" @click.prevent="downloadPdf">{{$t('offer.pdf')}}</o-button>
    </template>
  </o-form>
</template>

<script>
import axios from 'axios'

  export default {
    data: () => ({
      downloadingPdf: false
    }),

    mounted () {
    },

    methods: {
      downloadPdf() {
        this.downloadingPdf = true
        axios.get(`/api/cart-orders/${this.$route.params.id}/pdf`, {responseType: 'arraybuffer'}).then(response => {
          require('downloadjs')(response.data,
                `order_${this.$route.params.id}.pdf`,
                'application/pdf'
            )
          this.downloadingPdf = false
        })
      }
    }
  }
</script>
