<template>
  <o-card :title="title" :sync-page-title="syncPageTitle" :shadow="shadow" :bg-transparent="bgTransparent">
    <slot name="filter"></slot>
    <div class="table-responsive" ref="tableMainDiv" :class="{'sticky-table': stickyHeader}"
         :style="{'max-height': stickyHeader ? height + 'px' : '' }">
      <table class="table table-sm" :class="{'table-hover': fullRowClick && rowClickFunction}">
        <thead>
        <tr>
          <slot name="head"></slot>
          <slot name="head-controls">
            <th class="text-right" v-if="enableNew">
              <router-link :to="{ path: $route.path + '/new'}" class="btn btn-primary">
                {{$t('core.new')}}
              </router-link>
              <router-link v-for="(button, index) in extraButton" :key="index"
                           :to="{ path: $route.path + '/' + button.slug }" class="btn btn-primary">
                {{button.name}}
              </router-link>
            </th>
          </slot>
          <slot name="head-end"></slot>
        </tr>
        </thead>
        <tbody ref="tbody">
        <router-link  v-for="(item, itemIndex) in data.data" :key="itemIndex" tag="tr"
                      :class="{'bg-primary text-white': fullRowClick && rowClickFunction && itemIndex.toString() === selectedRow.toString(),
                      'bg-light-yellow': highlightRow(item)}"
                      @click.native="fullRowClick && rowClickFunction ? rowClickForward(item, itemIndex) : null"
                      :to="fullRowClick && !rowClickFunction ? {path: $route.path + '/' + item.id}: ''">
          <slot name="body" :item="item">
            no controls for body slot specified
          </slot>
          <slot name="body-controls" :item="item">
            <td class="text-right" v-if="enableDelete || enableEdit || enableView || extraRowButton || customRowButton">
              <div class="btn-group btn-group-sm" role="group" aria-label="edit controls">
                <o-delete v-if="enableDelete" method="delete" :url="deleteUrl() + '/' + item.id"
                          v-on:action-executed="search"></o-delete>
                <router-link v-if="enableView" :to="{ path: $route.path + '/view/' + item.id }" class="btn btn-primary">
                  <fa icon="eye"/>
                </router-link>
                <router-link v-if="enableEdit" :to="{ path: $route.path + '/' + item.id }" class="btn btn-primary">
                  <fa icon="pencil-alt"/>
                </router-link>
                <slot name="custom-row-button" v-if="extraRowButton || customRowButton" :item="item">
                  <router-link v-for="(button, index) in extraRowButton" :key="index"
                               :to="{ path: $route.path + '/' + item.id }" class="btn btn-primary">
                    <fa :icon="button.icon"/>
                  </router-link>
                </slot>
              </div>
            </td>
          </slot>
        </router-link>
        </tbody>
        <tfoot>
        <slot name="foot"></slot>
        </tfoot>
      </table>
    </div>

    <template slot="footer">
      <o-pagination :data="data.meta.pagination" v-on:change-page="changePage"
                    class="mt-3"></o-pagination>
    </template>
  </o-card>
</template>

<script>
  import axios from 'axios'
  import qs from 'qs'

  export default {
    name: 'o-table',

    props: {
      url: {
        type: String,
        required: true
      },
      title: {
        type: String,
        default: ''
      },
      syncPageTitle: {
        type: Boolean,
        default: true
      },
      enableNew: {
        type: Boolean,
        default: true
      },
      enableEdit: {
        type: Boolean,
        default: true
      },
      enableDelete: {
        type: Boolean,
        default: true
      },
      shadow: {
        type: Boolean,
        default: true
      },
      customDelete: {
        type: Boolean,
        default: false
      },
      enableView: {
        type: Boolean,
        default: false
      },
      extraButton: {
        type: Array,
        default: null
      },
      extraRowButton: {
        type: Array,
        default: null
      },
      customRowButton: {
        type: Boolean,
        default: false
      },
      defaultSortBy: {
        type: String,
        default: 'id'
      },
      defaultSortByDirection: {
        type: String,
        default: 'asc'
      },
      fullRowClick: {
        type: Boolean,
        default: false
      },
      rowClickFunction: {
        type: Boolean,
        default: false
      },
      bgTransparent: {
        type: Boolean,
        default: false
      },
      stickyHeader: {
        type: Boolean,
        default: false
      },
      onlyFilterable: {
        type: Boolean,
        default: false
      },
      highlightRow: {
        type: Function,
        default: () => {
          return false
        }
      }
    },

    data: () => ({
      data: {
        data: [],
        meta: {
          pagination: {
            current_page: 1
          }
        },
        filters: [],
        sortBy: '',
        sortByDirection: ''
      },
      selectedRow: [-1],
      height: 0
    }),

    computed: {},

    methods: {
      changePage(page) {
        this.data.meta.pagination.current_page = page
        this.search()
      },
      isIE() {
          var isIE11 = navigator.userAgent.indexOf(".NET CLR") > -1;
          var isIE11orLess = isIE11 || navigator.appVersion.indexOf("MSIE") != -1;
          return isIE11orLess;
      },
      async downloadExcel() {
        this.$root.$loading.start()

        await axios
          .get(this.url, {
            headers: {
              'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'blob',
            paramsSerializer: qs.stringify,
            params: {
              transformer: 'excel',
              page: this.data.meta.pagination.current_page,
              per_page: 999999,
              filter: this.filters,
              sortBy: this.sortBy,
              sortByDirection: this.sortByDirection
            }
          })
          .then((response) => {
            this.$root.$loading.finish()

            let name = 'export'

            if (!this.isIE()) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name +'.xlsx');
                document.body.appendChild(link);
                link.click();
            }
            else {
                window.navigator.msSaveOrOpenBlob(new Blob([response.data]),  name +'.xlsx');
            }
          })
          .catch((error) => {
            this.$root.$loading.finish()
            console.log(error)
            window.alert('could not load data from ' + this.url)
          })
      },
      search() {
        this.$root.$loading.start()

        axios
          .get(this.url, {
            paramsSerializer: qs.stringify,
            params: {
              page: this.data.meta.pagination.current_page,
              filter: this.filters,
              sortBy: this.sortBy,
              sortByDirection: this.sortByDirection
            }
          })
          .then((response) => {
            this.data = response.data
            this.resetSelectedRow()
            this.$emit('change-page', this.data.meta.pagination.current_page)

            if (this.stickyHeader) {
              this.setHeight()
              if (this.$refs.tableMainDiv) {
                this.$refs.tableMainDiv.scroll({ top: 0, behavior: 'smooth' })
              }
            }
            this.$root.$loading.finish()
          })
          .catch((error) => {
            this.$root.$loading.finish()
            console.log(error)
            window.alert('could not load data from ' + this.url)
          })
      },
      setFilters(filters) {
        var flatFilters = {}
        filters.forEach((item) => {
          flatFilters[item.column] = item.value
        })
        this.filters = flatFilters
        this.search()
      },
      sortColumn (options) {
        this.sortBy = options.column
        this.sortByDirection = options.direction

        // let all table header components know what column we sort by, we need this to activate the correct indicator
        // and also clear the previous sorted column indicator
        this.$slots.head.forEach((item) => {
          if (item && item.componentOptions && item.componentOptions.tag === 'o-table-header') {
            item.componentInstance.setSortProperties(this.sortBy, this.sortByDirection)
          }
        })

        this.search()
      },
      deleteUrl() {
        let url = this.url;
        if(this.customDelete) {
          let splitUrl = url.split("?");
          if(Array.isArray(splitUrl) && splitUrl.length && 0 in splitUrl) {
            return splitUrl[0];
          }
          return url
        }
        return url
      },
      rowClickForward (item, index) {
        this.$emit('row-click', item)
        this.$set(this.selectedRow, 0, index)
      },
      resetSelectedRow () {
        this.$set(this.selectedRow, 0, -1)
      },
      setHeight () {
        const windowHeight = window.innerHeight
        const deductHeight = this.data.meta.pagination.total_pages <= 1 ? 176 : 246
        const height = (windowHeight - deductHeight).toFixed()
        this.height = parseInt(height)
      }
    },
    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.setHeight)
      })
      this.sortBy = this.defaultSortBy
      this.sortByDirection = this.defaultSortByDirection
      if (!this.onlyFilterable) {
        this.search()
      }
      this.$on('sort-column', this.sortColumn)
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.setHeight)
    }
  }

</script>

<style>
.bg-light-yellow {
  background-color: lightyellow;
}
</style>
