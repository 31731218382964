<template>
    <div class="input-group">
        <div class="input-group-prepend mb-2">
            <select v-model="selectedFilter" class="form-control">
                <option v-for="(item, key) in items" :value="key">{{item.title}}</option>
            </select>
        </div>
        <div class="mr-2 mb-2">
            <slot ></slot>
        </div>
        <div v-for="(activeFilter, activeFilterKey) in activeFilters" class="mr-2 mb-2">
            <span class="btn btn-secondary">
              {{activeFilter.title}}: {{activeFilter.displayValue}}
              <fa icon="times" @click="removeFilter(activeFilterKey)"/>
            </span>
        </div>
    </div>
</template>

<script>
  import qs from 'qs'

  export default {
    name: 'o-filter',
    props: {
      single: {
        default: false,
        type: Boolean
      },
      defaultFilter: {
        default: function () {
          return []
        }
      },
      filterCacheable: {
        default: false,
        type: Boolean
      }
    },

    data: () => ({
      selectedFilter: null,
      items: [],
      uniqueIdentifier: '',
      activeFilters: []
    }),

    watch: {
      selectedFilter(value) {
        this.hideAllChildren()
        this.$children[value].$emit('set-classes', this.uniqueIdentifier + '-' + value)
      }
    },

    methods: {
      hideAllChildren() {
        this.$children.forEach((element, key) => {
          element.$emit('set-classes', 'd-none ' + this.uniqueIdentifier + '-' + key)
        })
      },
      setFilter(filter) {
        if(this.single) {
          this.activeFilters = [filter]
        } else {
          if(Object.keys(this.activeFilters).length) {
            var self = this
            self.activeFilters.forEach(function (item, filterKeyIndex) {
              if(item.column === filter.column) {
                self.activeFilters.splice(filterKeyIndex, 1)
              }
            })
          }
          this.activeFilters.push(filter)
        }

        if (this.filterCacheable) {
          this.$localStorage.set(this.$route.path, JSON.stringify(this.activeFilters))
        }

        this.$emit('filter-updated', this.activeFilters)
      },
      removeFilter(filterKeyIndex) {
        this.activeFilters.splice(filterKeyIndex, 1)

        if (this.filterCacheable) {
          this.$localStorage.set(this.$route.path, JSON.stringify(this.activeFilters))
        }

        this.$emit('filter-updated', this.activeFilters)
      },
      setDefaultFilter (defaultFilter) {
        if(this.single) {
          this.activeFilters = [defaultFilter]
        } else {
          this.activeFilters = defaultFilter
        }

        if (this.filterCacheable) {
          this.$localStorage.set(this.$route.path, JSON.stringify(this.activeFilters))
        }

        this.$emit('filter-updated', this.activeFilters)
      }
    },

    mounted() {
      var self = this
      self.uniqueIdentifier = Math.random().toString(36).substr(2, 9)
      self.items = self.$children
      if (self.items) {
        self.selectedFilter = 0
      }

      var defaultFilter = self.defaultFilter
      if (self.filterCacheable) {
        defaultFilter = self.$localStorage.get(self.$route.path, defaultFilter)

        if(typeof defaultFilter !== 'object') {
          try {
            defaultFilter = JSON.parse(defaultFilter)
          } catch (error) {
            defaultFilter = null
          }
        }
      }

      if (defaultFilter && Object.keys(defaultFilter).length) {
        self.setDefaultFilter(defaultFilter)
      }

      self.hideAllChildren()

      self.$on('enable-filter', self.setFilter)
    }
  }
</script>
