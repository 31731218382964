<template>
  <b-form-select v-model="localValue" :options="selectItems" :value-field="valueField"
                 :text-field="textField" :readonly="readonly"></b-form-select>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'o-value-select-control',
    props: {
      value: {
        default: null
      },
      url: {
        type: String,
        default: ''
      },
      valueField: {
        type: String,
        default: 'value'
      },
      textField: {
        type: String,
        default: 'text'
      },
      items: {
        default: function () { return [] }
      },
      readonly: {
        type: Boolean,
        default: false
      }
    },

    mounted() {
      this.localValue = this.value
    },

    watch: {
      value(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.localValue = newVal
        }
      },
      localValue(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('input', newVal)
        }
      }
    },

    created() {
      // no AJAX url specified, use options from property
      if (this.url === '') {
        this.selectItems = this.items
        return
      }

      axios
        .get(this.url, {params: {perPage: 0}})
        .then((response) => {
          this.selectItems = response.data.data
        })
        .catch((error) => {
          console.log(error)
          window.alert('could not fetch value list options from ' + this.url)
        })
    },

    data: () => ({
      selected: null,
      selectItems: [],
      localValue: ''
    })
  }
</script>
