<template>
    <div class="wizard-step">
        <slot></slot>
    </div>
</template>


<script>
  import axios from 'axios'

  export default {
    name: 'o-wizard-step',
    props: {
        title: {
            type: String
        }
    }
  }
</script>