<template>
  <nav class="w-100" v-if="data.total_pages > 1">
    <ul class="pagination justify-content-center">
      <li class="page-item">
        <a class="page-link" href="#" :class="{ 'disabled': data.current_page == 1 }"
           @click="changePage(data.current_page - 1)">
          Previous
        </a>
      </li>
      <li v-for="page in pages" class="page-item" :class="{ 'active': page == data.current_page }">
        <a class="page-link" @click="changePage(page)" href="#">
          {{page}}
        </a>
      </li>
      <li class="page-item">
        <a class="page-link" href="#" :class="{ 'disabled': data.total_pages == 1 }"
           @click="changePage(data.current_page + 1)">
          Next
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
  export default {
    name: 'o-pagination',
    computed: {
      pages() {
        var lowerBound = Math.max(1, this.data.current_page - Math.floor(this.maximumPages / 2))
        var upperBound = Math.min(this.data.total_pages, lowerBound + (this.maximumPages - 1))
        var pages = []

        for (var i = lowerBound; i <= upperBound; i++) {
          pages.push(i)
        }
        return pages
      }
    },
    methods: {
      changePage(page) {
        this.$emit('change-page', page)
      }
    },
    props: {
      data: {
        type: Object,
        required: true
      },
      maximumPages: {
        type: Number,
        default: 7
      }
    }
  }
</script>
