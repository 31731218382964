<template>
  <o-card :title="$t('core.home')">
    <div class="mt-1">
      {{ $t('core.welcome') }} {{ user && user.first_name ? user.first_name : user.name }}
    </div>
    <div class="mt-3" v-if="user && !user.email_verified_at">
      <b-alert show variant="warning">{{ $t('core.email_not_verified_error') }}</b-alert>
    </div>
  </o-card>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    components: {}
  }
</script>
