<template>
  <div class="sidebar d-md-block" :class="{'d-none': !visible}">
    <div class="sidebar-inner bg-primary">
      <div class="p-1 h-100">
        <div class="d-flex flex-column h-100">
          <div v-if="user && user.email_verified_at">
            <template v-if="authenticated">
              <o-sidebar-children v-if="navItems" :nav-items="navItems" child-classes="nav" classes="nav nav-pills" li-classes="nav-item"></o-sidebar-children>
            </template>
          </div>
          <div class="mt-auto" v-if="authenticated">
            <ul class="nav nav-pills">
              <li class="nav-item">
                <router-link :to="{ name: 'settings.profile' }" class="nav-link">
                  {{ $t('core.settings') }}
                </router-link>
              </li>
              <li class="nav-item">
                <a @click.prevent="logout" class="nav-link" href="#">
                  {{ $t('core.logout') }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import {i18n} from '~/plugins/i18n'

  export default {
    name: 'o-sidebar',
    props: ['visible'],

    methods: {
      async logout() {
        // Log out the user.
        await this.$store.dispatch('auth/logout')

        // Redirect to login.
        this.$router.push({name: 'login'})
      }
    },

    computed: {
      ...mapGetters({
        authenticated: 'auth/check',
        navItems: 'navigation/navItems',
        user: 'auth/user'
      })
    }
  }
</script>
