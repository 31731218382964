export default {
  methods: {
    findParentComponent: function (tagName) {
      var parent = this.$parent
      while (parent) {
        if (parent.$options.name === tagName) {
          return parent
        } else {
          parent = parent.$parent
        }
      }
    }
  }
}
