<template>
  <o-form :title="$t('offer.wholesaler')"
          :url="`/api/cart-wholesalers/${$route.params.id}`"
          class="ortic-form-horizontal">
    <template slot-scope="props">
      <b-row>
        <b-col>
          <o-input :label="$t('offer.wholesaler')" :errorField="'wholesaler'" v-model="props.item.wholesaler"/>
          <o-input :label="$t('offer.recipient_email')" :errorField="'recipient_email'" v-model="props.item.recipient_email"/>
          <o-value-select :label="$t('offer.category')" :errorField="'category'" :items="categories" v-model="props.item.category"></o-value-select>
          <o-value-select :label="$t('offer.logistics_surcharge')" :errorField="'logistics_surcharge'" :items="logisticsSurcharges" v-model="props.item.logistics_surcharge"></o-value-select>
          <o-textarea :label="$t('offer.hint')" :errorField="'hint'" v-model="props.item.hint"></o-textarea>
        </b-col>
      </b-row>
    </template>
  </o-form>
</template>

<script>
  export default {
    data: () => ({
      categories: [],
      logisticsSurcharges: []
    }),

    created () {
      this.categories.push({value: 'internal', text: this.$t('offer.internal')})
      this.categories.push({value: 'external', text: this.$t('offer.external')})

      this.logisticsSurcharges.push({value: 'unknown', text: this.$t('offer.unknown')})
      this.logisticsSurcharges.push({value: 'none', text: this.$t('offer.none')})
      this.logisticsSurcharges.push({value: '33_25p', text: this.$t('offer.33_25p')})
    },

    methods: {
    }
  }
</script>
