<template>
  <div>
    <form @submit.prevent="save">
      <slot name="main" :item="item" v-if="item">
        <o-card :title="title" v-if="item" :sync-page-title="syncPageTitle">
          <slot :item="item">
            no controls for body slot specified
          </slot>
        </o-card>
      </slot>
      <slot name="child" :item="item" v-if="item">
      </slot>
      <slot name="footer" :item="item" v-if="item">
        <o-action-toolbar>
          <div class="d-flex w-100">
            <slot name="footer-toolbar-left-before" :item="item"></slot>
            <o-button variant="outline-primary" v-if="enableBack" @click.prevent="back">{{$t('core.back')}}</o-button>
            <slot name="footer-toolbar-left-after" :item="item"></slot>
            
            <span class="ml-auto">
              <slot name="footer-toolbar-right-before" :item="item"></slot>
              <o-button variant="primary" v-if="enableSave" :loading="saving" @click.prevent="save">{{$t('core.save')}}</o-button>
              <slot name="footer-toolbar-right-after" :item="item"></slot>
            </span>
          </div>
        </o-action-toolbar>
      </slot>
    </form>
  </div>
</template>

<script>
  import axios from 'axios'
  import {i18n} from '~/plugins/i18n'

  export default {
    name: 'o-form',
    props: {
      url: {
        type: String,
        required: true
      },
      title: {
        type: String,
        default: ''
      },
      syncPageTitle: {
        type: Boolean,
        default: true
      },
      dataKey: {
        type: String,
        default: 'data'
      },
      enableSave: {
        type: Boolean,
        default: true
      },
      enableBack: {
        type: Boolean,
        default: true
      }
    },

    data: () => ({
      item: {},
      saving: false
    }),

    computed: {},

    methods: {
      save() {
        var url = this.url
        var route = this.$route

        // this happens if we create a new data record and update it right away without navigating way. We'll still have
        // new in our url, but we don't want to create a new record, we want to update the record we just created.
        // @TODO we should do this in a more elegant way that doesn't require search & replace
        if (url.indexOf('/new') > 0 && 'id' in this.item) {
          url = url.replace('/new', '/' + this.item.id)
        }

        var root = this.$root
        var self = this

        this.saving = true

        axios
          .post(url, this.item)
          .then((response) => {
            this.item = response.data

            // assign our new id to the route params in case someone wants to work with it
            if ('id' in response.data) {
              route.params.id = response.data.id
            }

            this.$notify({
              title: this.$i18n.t('core.saved'),
              text: this.$i18n.t('core.saved_text')
            })

            // remove all error states
            var elements = document.querySelectorAll('.has-error')
            elements.forEach((element) => {
              element.classList.remove('has-error')
            })

            // clear all errors
            root.errors = {}

            // stop loading indicator
            self.saving = false
          })
          .catch((error) => {
            // stop loading indicator
            self.saving = false

            if (error.response.status === 422 && 'data' in error.response && 'message' in error.response.data) {
              this.$notify({
                title: this.$i18n.t('core.validation_error'),
                text: this.$i18n.t('core.validation_error_text', {message: error.response.data.message}),
                type: 'error'
              })
              if ('data' in error.response && 'errors' in error.response.data) {
                root.errors = error.response.data.errors
              }
            } else {
              this.$notify({
                title: this.$i18n.t('core.save_error'),
                text: this.$i18n.t('core.save_error_text', {url: this.url}),
                type: 'error'
              })
            }
          })
      },
      back() {
        this.$router.back()
      },
      load(url) {
        return axios
          .get(url)
          .then((response) => {
            var item = response
            if (this.dataKey) {
              item = response[this.dataKey]
            }
            this.item = item
          })
          .catch((error) => {
            this.$notify({
              title: this.$i18n.t('core.load_error'),
              text: this.$i18n.t('core.load_error_text', {url: this.url}),
              type: 'error'
            })
          })
      }
    },

    mounted() {
      this.$on('reload-with-file-data', (callback) => {
        this.load(this.url + ((this.url.indexOf('?') > -1 ? '&' : '?') + 'read-file-data')).then((response) => {
          this.$nextTick(() => {
            callback(response)
          })
        })
      })
    },

    created() {
      this.load(this.url)
    }
  }

</script>
