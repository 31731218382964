<script>
  export default {
    name: 'o-page-title',
    mounted() {
      document.title = this.title + ' – ' + window.config.appName
    },
    props: {
      title: {
        type: String,
        required: true
      }
    }
  }
</script>
