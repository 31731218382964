<template>
  <div>
    <nav class="navbar fixed-top navbar-light bg-light">
      <a class="navbar-brand" href="/">
        <img src="images/novartis-sandoz-logo.svg" height="30" alt="">
      </a>
      <div style="position: absolute; width: 100%;">
        <div class="container">
          <a class="ml-auto mr-4 btn btn-primary reminder-button" href="#" @click="$router.go(-1)">{{ $t('core.back') }}</a>
          <LocaleDropdown class="right-locale" :right="true"/>
        </div>
      </div>
    </nav>
    <div class="container" id="content">    
      <div class="row">
        <div class="col-12">
          <div v-html="$t(legalContent())"></div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-12 footer">
          <img :src="footerImage()" alt="Novartis">
          <ul class="footer-links">
            <li class="footer-links-item">
              <router-link to="contact">{{ $t('core.contact') }}</router-link>
            </li>
            <li class="footer-links-item">
              <router-link to="terms_of_use">{{ $t('core.terms_of_use') }}</router-link>
            </li>
            <li class="footer-links-item">
              <router-link to="privacy_policy">{{ $t('core.privacy_policy') }}</router-link>
            </li>
            <li class="footer-links-item">
              <router-link to="about_cookies">{{ $t('core.about_cookies') }}</router-link>
            </li>
            <li class="footer-links-item">
              <a href="#" id="custom-ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</a>
            </li>
            <li class="footer-links-item">
              <a href="#">Juni 2022, CH2206159729</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import LocaleDropdown from '~/components/LocaleDropdown'

export default {
  components: {LocaleDropdown},
  data() {
    return {
    }
  },
  methods: {
    footerImage() {
      if (this.locale === 'fr') return "/images/footer_brand_fr.svg"
      if (this.locale === 'it') return "/images/footer_brand_fr.svg"
      return "/images/footer_brand_de.svg"
    },
    legalContent() {
      if (this.$store.state.route.name === 'contact') return 'core.contact_content'
      if (this.$store.state.route.name === 'terms_of_use') return 'core.terms_of_use_content'
      if (this.$store.state.route.name === 'privacy_policy') return 'core.privacy_policy_content'
      if (this.$store.state.route.name === 'about_cookies') return 'core.about_cookies_content'
    }
  },
  layout: 'default',
  created() {
  },
  computed: mapGetters({
    locale: 'lang/locale',
    user: 'auth/user'
  }),
}
</script>