<template>
  <div :class="containerClasses">
    <input type="number" class="form-control" v-model="value" @keyup.enter="enableFilter">
  </div>
</template>

<script>
  import qs from 'qs'

  export default {
    name: 'o-filter-number',
    props: {
      title: {
        type: String,
        required: true
      },
      column: {
        type: String,
        required: false
      },
      classes: {
        type: String,
        default: ''
      }
    },

    data: () => ({
      containerClasses: '',
      value: ''
    }),

    methods: {
      enableFilter() {
        this.$parent.$emit('enable-filter', {
          column: this.column ? this.column : this.title,
          title: this.title,
          value: this.value,
          displayValue: this.value
        })
        this.value = ''
      },
      checkUrlFilter() {
        // check url hash to see if we have a filter in the uri
        if (!document.location.search) {
          return
        }
        var params = qs.parse(document.location.search.substr(1))

        if (this.column in params.filter) {
          this.$parent.$emit('enable-filter', {
            column: this.column ? this.column : this.title,
            title: this.title,
            value: params.filter[this.column],
            displayValue: params.filter[this.column]
          })
        }
      }
    },

    mounted() {
      this.$on('set-classes', (classes) => {
        this.containerClasses = classes
      })
      this.checkUrlFilter()
    }
  }
</script>
