<template>
  <o-card :title="$t('core.your_info')">
    <form @submit.prevent="update" @keydown="form.onKeydown($event)">
      <alert-success :form="form" :message="$t('core.info_updated')"></alert-success>

      <!-- Name -->
      <div class="form-group row">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('core.name') }}</label>
        <div class="col-md-7">
          <input v-model="form.name" type="text" name="name" class="form-control"
                 :class="{ 'is-invalid': form.errors.has('name') }">
          <has-error :form="form" field="name"></has-error>
        </div>
      </div>

      <!-- First Name -->
      <div class="form-group row">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('core.first_name') }}</label>
        <div class="col-md-7">
          <input v-model="form.first_name" type="text" name="first_name" class="form-control"
                 :class="{ 'is-invalid': form.errors.has('first_name') }">
          <has-error :form="form" field="first_name"></has-error>
        </div>
      </div>

      <!-- Second Name -->
      <div class="form-group row">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('core.second_name') }}</label>
        <div class="col-md-7">
          <input v-model="form.second_name" type="text" name="second_name" class="form-control"
                 :class="{ 'is-invalid': form.errors.has('second_name') }">
          <has-error :form="form" field="second_name"></has-error>
        </div>
      </div>

      <!-- Email -->
      <div class="form-group row">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('core.email') }}</label>
        <div class="col-md-7">
          <input v-model="form.email" type="email" name="email" class="form-control"
                 :class="{ 'is-invalid': form.errors.has('email') }">
          <has-error :form="form" field="email"></has-error>
        </div>
      </div>

      <!-- Phone -->
      <div class="form-group row">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('core.phone') }}</label>
        <div class="col-md-7">
          <input v-model="form.phone" type="tel" name="phone" class="form-control"
                 :class="{ 'is-invalid': form.errors.has('phone') }">
          <has-error :form="form" field="phone"></has-error>
        </div>
      </div>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-md-9 ml-md-auto">
          <o-button type="success" :loading="form.busy">{{ $t('core.update') }}</o-button>
        </div>
      </div>
    </form>
  </o-card>
</template>

<script>
  import Form from 'vform'
  import {mapGetters} from 'vuex'

  export default {
    data: () => ({
      form: new Form({
        name: '',
        email: '',
        phone: '',
        first_name: '',
        second_name: ''
      })
    }),

    computed: {
      ...mapGetters({
        user: 'auth/user'
      }),
    },

    created() {
      // Fill the form with user data.
      this.form.keys().forEach(key => {
        this.form[key] = this.user[key]
      })
    },

    methods: {
      async update() {
        const {data} = await this.form.patch('/api/settings/profile')

        this.$store.dispatch('auth/updateUser', {user: data})
      }
    }
  }
</script>
