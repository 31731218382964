<template>
  <o-table :title="$t('offer.offerlist')" url="/api/offers?sortBy=offer_start" :custom-delete="true" ref="tableList">
    <template slot="filter">
        <o-filter :default-filter="defaultFilter" v-on:filter-updated="(filters) => $refs.tableList.setFilters(filters)">
            <o-filter-select :items="dateStatus" :title="$t('offer.date')" column="date_status"></o-filter-select>
        </o-filter>
    </template>  
    <template slot="head">
      <o-table-header column="title">{{$t('core.title')}}</o-table-header>
      <o-table-header column="offer_start">{{$t('offer.start')}}</o-table-header>
      <o-table-header column="offer_end">{{$t('offer.end')}}</o-table-header>
    </template>
    <template slot="body" slot-scope="props">
      <td>{{ offerTitle(props.item) }}</td>
      <td>{{ props.item.offer_start | formatDate }}</td>
      <td>{{ props.item.offer_end | formatDate }}</td>
    </template>
  </o-table>
</template>

<script>
import Vue from 'vue'
import axios from "axios";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      defaultFilter: [],
      dateStatus: []
    }
  },
  methods: {
    offerTitle(el) {
      if (this.locale === 'fr') return el.title_fr
      if (this.locale === 'it') return el.title_it
      return el.title_de
    }
  },
  created() {
    this.dateStatus = [
      {value: 'past', text: this.$i18n.t('offer.past_date')},
      {value: 'future', text: this.$i18n.t('offer.future_date')}
    ]
    
    this.defaultFilter = [{
      column: 'date_status',
      displayValue: this.$i18n.t('offer.future_date'),
      title: this.$i18n.t('offer.date'),
      value: 'future'
    }]
  },
  computed: mapGetters({
    locale: 'lang/locale',
    user: 'auth/user'
  }),
}
</script>