<template>
  <b-form-checkbox v-model="localValue" :readonly="readonly">{{ label }}</b-form-checkbox>
</template>

<script>
  export default {
    name: 'o-checkbox-control',
    props: {
      value: {
        default: null
      },
      readonly: {
        type: Boolean,
        default: false
      },
      label: {
        default: null
      }
    },

    data: () => ({
      localValue: ''
    }),

    mounted() {
      this.localValue = this.value
    },

    components: {},

    watch: {
      value(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.localValue = newVal
        }
      },
      localValue(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('input', newVal)
        }
      }
    },

    methods: {}
  }
</script>
