<template>
  <div :class="containerClasses">
      <o-datepicker-control ref="picker" v-model="value" @input="updated" :typeable="false" :readonly="false" :disabled="false" :clear="false"></o-datepicker-control>
  </div>
</template>

<script>
  import qs from 'qs'

  export default {
    name: 'o-filter-datepicker',
    props: {
      title: {
        type: String,
        required: true
      },
      column: {
        type: String,
        required: false
      },
      classes: {
        type: String,
        default: ''
      }
    },

    data: () => ({
      containerClasses: '',
      value: ''
    }),

    methods: {
      updated() {
        this.enableFilter()
      },
      enableFilter() {
        this.$parent.$emit('enable-filter', {
          column: this.column ? this.column : this.title,
          title: this.title,
          value: this.value,
          displayValue: this.$refs.picker.customFormatter(this.value)
        })
        this.value = ''
      },
      checkUrlFilter() {
        // check url hash to see if we have a filter in the uri
        if (!document.location.search) {
          return
        }
        var params = qs.parse(document.location.search.substr(1))

        if (this.column in params.filter) {
          this.$parent.$emit('enable-filter', {
            column: this.column ? this.column : this.title,
            title: this.title,
            value: params.filter[this.column],
            displayValue: params.filter[this.column]
          })
        }
      }
    },

    mounted() {
      this.$on('set-classes', (classes) => {
        this.containerClasses = classes
      })
      this.checkUrlFilter()
    }
  }
</script>
