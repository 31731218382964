<template>
  <o-form :title="$t('offer.offerdetails')" :url="'/api/offers/' + $route.params.id + '?include=reminders,products,discounts,products.product'"
          class="ortic-form-horizontal" ref="form">
    <template v-slot="props">
      <b-row>
        <b-col cols="8">
          <o-input :errorField="'title_de'" :label="$t('offer.title_de')" v-model="props.item.title_de"/>
          <o-input :errorField="'title_fr'" :label="$t('offer.title_fr')" v-model="props.item.title_fr"/>
          <o-input :errorField="'title_it'" :label="$t('offer.title_it')" v-model="props.item.title_it"/>
          <o-textarea :errorField="'description_de'" :label="$t('offer.description_de')"
                      v-model="props.item.description_de"/>
          <o-textarea :errorField="'description_fr'" :label="$t('offer.description_fr')"
                      v-model="props.item.description_fr"/>
          <o-textarea :errorField="'description_it'" :label="$t('offer.description_it')"
                      v-model="props.item.description_it"/>
          <o-datepicker :errorField="'offer_start'" :label="$t('offer.start')" v-model="props.item.offer_start"/>
          <o-datepicker :errorField="'offer_end'" :label="$t('offer.end')" v-model="props.item.offer_end"/>
          <o-input :errorField="'saving_percent'" :label="$t('offer.saving')" v-model="props.item.saving_percent"/>
          <o-input :errorField="'saving_percent_text_de'" :label="$t('offer.saving_text')"
                   v-model="props.item.saving_percent_text_de"/>
          <o-input :errorField="'saving_percent_text_fr'" :label="$t('offer.saving_text')"
                   v-model="props.item.saving_percent_text_fr"/>
          <o-input :errorField="'saving_percent_text_it'" :label="$t('offer.saving_text')"
                   v-model="props.item.saving_percent_text_it"/>
          <o-file-upload :errorField="'image_de'" :label="$t('offer.image_de')"
                         v-model="props.item.image_de"></o-file-upload>
          <o-file-upload :errorField="'image_fr'" :label="$t('offer.image_fr')"
                         v-model="props.item.image_fr"></o-file-upload>
          <o-file-upload :errorField="'image_it'" :label="$t('offer.image_it')"
                         v-model="props.item.image_it"></o-file-upload>
          <o-file-upload :errorField="'download_de'" :label="$t('offer.download_de')"
                         v-model="props.item.download_de"></o-file-upload>
          <o-form-group>
            <span v-if="props.item.download_de" class="form-control">{{ downloadName(props.item.download_de) }}</span>
          </o-form-group>
          <o-file-upload :errorField="'download_fr'" :label="$t('offer.download_fr')"
                         v-model="props.item.download_fr"></o-file-upload>
          <o-form-group>
            <span v-if="props.item.download_fr" class="form-control">{{ downloadName(props.item.download_fr) }}</span>
          </o-form-group>
          <o-file-upload :errorField="'download_it'" :label="$t('offer.download_it')"
                         v-model="props.item.download_it"></o-file-upload>
          <o-form-group>
            <span v-if="props.item.download_it" class="form-control">{{ downloadName(props.item.download_it) }}</span>
          </o-form-group>
          <o-value-select :errorField="'color'" :items="coloroptions" :label="$t('offer.color')"
                          v-model="props.item.color"/>
          <o-input :errorField="'link_kam'" :label="$t('offer.link_kam')" v-model="props.item.link_kam"/>
          <o-input :errorField="'link_ff'" :label="$t('offer.link_ff')" v-model="props.item.link_ff"/>

          <o-input :errorField="'compliance_info'" :label="$t('offer.compliance_info')" v-model="props.item.compliance_info"/>  
          <o-checkbox :errorField="'online_ordering_enabled'" :label="$t('offer.online_ordering_enabled')" v-model="props.item.online_ordering_enabled"/>          

        </b-col>
        <b-col cols="4">
          <img class="preview img-fluid" v-if="props.item.image_de" :src="getPreview(props.item.image_de)" alt="">
          <img class="preview img-fluid" v-if="props.item.image_fr" :src="getPreview(props.item.image_fr)" alt="">
          <img class="preview img-fluid" v-if="props.item.image_it" :src="getPreview(props.item.image_it)" alt="">
        </b-col>
        <b-col>
          
        </b-col>
      </b-row>
    </template>
    <template slot="footer-toolbar-right-before">
      <button class="btn btn-primary" @click.prevent="cloneOffer">{{ $t('offer.clone_offer') }}</button>
    </template>
    <template slot="child" slot-scope="props">
      <b-tabs>
        <b-tab :title="$t('offer.products')" active>
          <o-card>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                      <th>{{$t('offer.product')}}</th>
                      <th>{{$t('offer.package')}}</th>
                      <th class="text-right">
                          <b-btn @click.prevent="showProductDialog" variant="primary">
                              <fa icon="plus"/>
                          </b-btn>
                      </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(product, i) in props.item.products" :key="i">
                    <td>{{product.product.product}}</td>
                    <td>{{product.product.package}}</td>
                    <td class="text-right text-nowrap">
                      <div aria-label="edit controls" class="btn-group btn-group-sm" role="group">
                        <b-btn @click.prevent="props.item.products.splice(i, 1)" variant="secondary">
                          <fa icon="times"/>
                        </b-btn>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </o-card>
        </b-tab>
        <b-tab :title="$t('offer.discounts')">
          <o-card>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                      <th>{{$t('offer.min_quantity')}}</th>
                      <th>{{$t('offer.discount_percentage')}}</th>
                      <th class="text-right">
                          <b-btn @click.prevent="props.item.discounts.push({min_quantity: null, discount_percentage: null})" variant="primary">
                              <fa icon="plus"/>
                          </b-btn>
                      </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(discount, i) in props.item.discounts" :key="i">
                    <td><o-input-control v-model="discount.min_quantity"/></td>
                    <td><o-input-percentage-control v-model="discount.discount_percentage"/></td>
                    <td class="text-right text-nowrap">
                      <div aria-label="edit controls" class="btn-group btn-group-sm" role="group">
                        <b-btn @click.prevent="props.item.discounts.splice(i, 1)" variant="secondary">
                          <fa icon="times"/>
                        </b-btn>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </o-card>
        </b-tab>
        <b-tab :title="$t('offer.reminders')">
          <o-card>
            <div class="table-responsive">
              <b-btn @click.prevent="xlsx()" variant="primary">
                <fa icon="file-excel"/> {{$t('core.export')}}
              </b-btn>
              <b-btn @click.prevent="sendTest()" variant="primary">
                <fa icon="paper-plane"/> {{$t('offer.send_test')}}
              </b-btn>
              <table class="table">
                <thead>
                <tr>
                  <th>{{$t('core.email')}}</th>
                  <th>{{$t('offer.reminder_set_at')}}</th>
                  <th>{{$t('offer.reminder_sent_at')}}</th>
                  <th>{{$t('offer.reminder_end_sent_at')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(reminder, i) in props.item.reminders" :key="i">
                  <td>{{reminder.email}}</td>
                  <td>{{reminder.created_at | formatDate }}</td>
                  <td>{{reminder.sent | formatDate }}</td>
                  <td>{{reminder.end_sent | formatDate }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </o-card>
        </b-tab>
      </b-tabs>
      <b-modal :title="$t('offer.add_product')" @ok="close" id="modal-add-edit" ref="productModal" size="lg">
        <o-table url="/api/products" ref="productList" :custom-row-button="true" :enable-new="false" :shadow="false">
          <template slot="filter">
            <o-filter v-on:filter-updated="(filters) => $refs.productList.setFilters(filters)">
              <o-filter-text :title="$t('offer.product')" column="product"></o-filter-text>
              <o-filter-text :title="$t('offer.package')" column="package"></o-filter-text>
            </o-filter>
          </template>
          <template slot="head">
            <o-table-header column="product">{{$t('offer.product')}}</o-table-header>
            <o-table-header column="package">{{$t('offer.package')}}</o-table-header>
          </template>
          <template slot="body" slot-scope="props">
            <td>{{ props.item.product }}</td>
            <td>{{ props.item.package }}</td>
          </template>
          <template slot="body-controls" slot-scope="props">
            <td class="text-right">
              <div class="btn-group btn-group-sm" role="group" aria-label="edit controls">
                <b-btn variant="secondary" @click.prevent="selectProduct(props.item)">
                  <fa icon="sign-in-alt"/>
                </b-btn>
              </div>
            </td>
          </template>
        </o-table>
      </b-modal>
    </template>
  </o-form>
</template>
<script>

import axios from "axios";

export default {
  data() {
    return {
      coloroptions: [
        {value: 'red', text: 'Rot'},
        {value: 'orange', text: 'Orange'},
        {value: 'yellow', text: 'Gelb'},
        {value: 'green', text: 'Grün'},
        {value: 'blue', text: 'Blau'},
        {value: 'purple', text: 'Lila'}
      ]
    }
  },
  methods: {
    downloadName(download) {
      if (typeof download === 'object' && download !== null) {
        return download.name
      } else {
        return '/storage/offers/downloads/' + download
      }
    },

    getPreview(image) {
      if (typeof image === 'object' && image !== null) {
        return image.data
      } else {
        return '/storage/offers/images/' + image
      }
    },

    xlsx(url, config) {
      var $self = this
      axios.get(`/api/offers/${this.$route.params.id}/export`, {responseType: 'arraybuffer'})
          .then((response) => {
            require('downloadjs')(response.data,
                $self.$i18n.t('offers.reminders') + '.xlsx',
                'application/vnd.ms-excel'
            )
          })
          .catch((error) => {
                console.log(error)
              }
          )
    },

    sendTest() {
      var email = window.prompt(this.$t('offer.recipient'))
      if (email) {
        axios.post(`/api/offers/${this.$route.params.id}/send-test`, {email: email})
        window.alert(this.$t('offer.email_sent'))
      }
    },

    cloneOffer() {
      axios.post(`/api/offers/clone/${this.$route.params.id}`)
          .then((response) => {
            this.$router.push({ name: 'offers.details', params: { id: response.data.newId } })
            location.reload()
          }).catch((error) => {

      })
    },

    showProductDialog() {
      this.$refs.productModal.show()
    },

    selectProduct(item) {
      this.$refs.form.item.products.push({
          'offer_id': this.$refs.form.item.id,
          'product_id': item.id,
          'product': item
        })
        this.$refs.productModal.hide()
    }
  }
}
</script>

<style>
.preview {
  max-width: 100%;
  height: auto;
}
#modal-add-edit___BV_modal_outer_ {
  z-index: 9999 ! important;
}
</style>