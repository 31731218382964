<template>
  <o-form-group :label="label" :error-field="errorField" :class="{'has-error': errorField in $root.errors}">
    <o-value-select-control v-model="localValue" :value-field="valueField" :url="url" :items="items"
                            :text-field="textField" :readonly="readonly"></o-value-select-control>
  </o-form-group>
</template>

<script>

  export default {
    name: 'o-value-select',
    props: {
      value: {
        default: null
      },
      url: {
        type: String,
        default: ''
      },
      label: {
        type: String
      },
      valueField: {
        type: String,
        default: 'value'
      },
      errorField: {
        type: String
      },
      textField: {
        type: String,
        default: 'text'
      },
      items: {
        default: function () { return [] }
      },
      readonly: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      localValue: ''
    }),

    mounted() {
      this.localValue = this.value
    },

    watch: {
      value(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.localValue = newVal
        }
      },
      localValue(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('input', newVal)
        }
      }
    },

    methods: {}
  }

</script>
