<template>
  <div class="w-100 btn btn-secondary">
    <div v-if="supported">
      <div class="flex-container">
        <div class="flex-column-left">
          <label class="file-upload-contrainer w-100">
            <span v-if="model && model.name">{{model.name}}</span>
            <span v-else>{{$t('core.choose_file')}}</span>
            <input type="file" ref="file" @change="handleFile" class="inputfile" :accept="accept">
          </label>
        </div>
        <div class="flex-column-right" v-if="(model && model.name) && (allowClear || allowDownload)">
          <fa v-if="allowDownload && !downloading" icon="download" @click.prevent="download"/>
          <fa v-if="allowDownload && downloading" icon="spinner" :spin="true"/>
          <fa v-if="allowClear" icon="times" @click.prevent="clear" style="z-index: 9999"/>
        </div>
      </div>
    </div>
    <div v-if="!supported">
      file upload not supported by your browser
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .btn {
    padding: 0;
  }
  .flex-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    .flex-column-left {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
      width: 100%;
    }
    .flex-column-right {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
      padding: 10px;
    }
  }
  .file-upload-contrainer {
    overflow: hidden;
    position: relative;
    height: 100%;
    padding: 10px 0 0 0 ! important;
    [type=file] {
      cursor: inherit;
      display: block;
      font-size: 999px;
      filter: alpha(opacity=0);
      min-height: 100%;
      min-width: 100%;
      opacity: 0;
      position: absolute;
      right: 0;
      text-align: right;
      top: 0;
    }
  }
</style>

<script>
  import parentFinder from '../plugins/parent-finder'

  export default {
    name: 'o-file-upload-control',
    mixins: [parentFinder],
    props: {
      value: {},
      allowClear: {
        type: Boolean,
        default: true
      },
      allowDownload: {
        type: Boolean,
        default: true
      },
      accept: {
        type: String
      }
    },

    data: () => ({
      downloading: false
    }),

    computed: {
      supported: {
        get () {
          return 'FileReader' in window
        }
      },
      model: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    methods: {
      clear () {
        this.model = null
      },
      // this method will download the file if the data is already available in our file object
      downloadFile (fileObject) {
        var downloadFunction = require('downloadjs')
        downloadFunction(fileObject.data, fileObject.name)
      },
      // usually when loading a form the file data isn't loaded right away to safe bandwith, send a request to the o-form component to fetch the data
      requestDownloadFile () {
        this.downloading = true
        var form = this.findParentComponent('o-form')
        if (form === null) {
          this.$notify({
            title: this.$i18n.t('core.parent_component_not_found'),
            text: this.$i18n.t('core.parent_component_not_found_text'),
            type: 'error'
          })
        }
        form.$emit('reload-with-file-data', () => {
          this.downloading = false
          this.downloadFile(this.model)
        })
      },
      download () {
        if ('data' in this.model) {
          this.downloadFile(this.model)
        } else {
          this.requestDownloadFile()
        }
      },
      handleFile (evt) {
        var fileName = this.$refs.file.value.replace(/^.*[\\/]/, '')
        var files = evt.target.files
        var file = files[0]
        var self = this

        if (fileName.length === 0) {
          return
        }

        this.$emit('file-name-changed', fileName)

        if (files && file) {
          var reader = new window.FileReader()

          reader.onload = function (readerEvt) {
            self.model = {
              data: readerEvt.target.result,
              name: fileName
            }
          }

          reader.readAsDataURL(file)
        }
      }
    }
  }
</script>
