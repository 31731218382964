<template>
  <div class="form-group">
    <label>{{label}}</label>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'o-form-group',
    props: {
      label: {
        type: String
      }
    }
  }
</script>
