import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.component('fa', FontAwesomeIcon)

import {
  faUser, faLock, faSignOutAlt, faCog, faBars, faPencilAlt, faTimes, faPlus, faCalendarAlt, faEnvelope, faShoppingCart
} from '@fortawesome/fontawesome-free-solid'

library.add(
  faUser, faLock, faSignOutAlt, faCog, faBars, faPencilAlt, faTimes, faPlus, faCalendarAlt, faEnvelope, faShoppingCart
)
