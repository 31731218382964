<template>
  <o-form-group :label="label" class="ortic-value-select-modal">
    <o-value-select-modal-control v-model="localValue" :type="type" ref="control" :url="url"
                                  :display-field="displayField" :value-field="valueField">
      <template slot="head">
        <slot name="head"></slot>
      </template>
      <template slot="body" slot-scope="props">
        <slot name="body" :item="props.item"></slot>
      </template>
      <template slot="body-controls" slot-scope="props">
        <td class="text-right">
          <div class="btn-group btn-group-sm" role="group" aria-label="edit controls">
            <b-btn variant="primary" @click="selectValue(props.item)">
              <fa icon="arrow-right"/>
            </b-btn>
          </div>
        </td>
      </template>
    </o-value-select-modal-control>
  </o-form-group>
</template>

<script>
  import OrticFormGroup from '~/components/OrticFormGroup'
  import OrticValueSelectModalControl from './OrticValueSelectModalControl'

  export default {
    name: 'o-value-select-modal',
    props: {
      value: {
        default: null
      },
      label: {
        type: String
      },
      type: {
        type: String,
        default: 'text'
      },
      url: {
        type: String,
        required: true
      },
      displayField: {
        type: String,
        default: null
      },
      valueField: {
        type: String,
        default: 'id'
      }
    },

    mounted() {
      this.localValue = this.value
    },

    data: () => ({
      localValue: ''
    }),

    methods: {
      selectValue(item) {
        this.$refs.control.$emit('select-value', item)
      }
    },

    watch: {
      value(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.localValue = newVal
        }
      },
      localValue(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('input', newVal)
        }
      }
    },

    components: {
      OrticValueSelectModalControl,
      OrticFormGroup
    }
  }
</script>
