<template>
  <div class="app-layout d-flex flex-column" :class="{'sidebar-hidden': !sidebarVisible, 'sidebar-visibe': sidebarVisible, 'user-authenticated': authenticated, 'user-not-authenticated': !authenticated }">
    <vue-perfect-scrollbar class="h-100">
      <navbar v-on:toggle-sidebar="toggleSidebar"></navbar>
      <div class="container-fluid col pb-5 sidebar-content h-100" id="content">
        <div class="row h-100">
          <sidebar :visible="sidebarVisible" v-if="authenticated"></sidebar>
          <div class="col innercontent">
            <div class="p-4 mb-3">
              <o-child/>
            </div>
          </div>
        </div>
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import Navbar from '~/components/Navbar'
import Sidebar from '~/components/Sidebar'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters } from 'vuex'

export default {
  name: 'app-layout',

  data: () => ({
    sidebarVisible: false
  }),

  computed: {
    ...mapGetters({
      authenticated: 'auth/check'
    }),
    containerClasses () {
      return ''
      if (this.authenticated) {
        return 'col-sm-12 ml-sm-auto col-md-8 col-lg-9 col-xl-9 pt-0'
      }
      return 'col-sm-12 mx-sm-auto col-md-12 col-lg-10 col-xl-8 pt-0'
    }
  },

  components: {
    Navbar,
    Sidebar,
    VuePerfectScrollbar
  },

  methods: {
    toggleSidebar () {
      this.sidebarVisible = !this.sidebarVisible
    }
  }
}
</script>
