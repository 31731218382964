<template>
    <input v-model="localValue" :type="type" class="form-control form-control-percentage" :readonly="readonly" :placeholder="placeholder" step="0.01">
</template>

<script>
  export default {
    name: 'o-input-percentage-control',
    props: {
      value: {
        default: null
      },
      type: {
        type: String,
        default: 'number'
      },
      readonly: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String
      },
      multiplication: {
        type: Number,
        default: 100
      }
    },

    data: () => ({
      localValue: ''
    }),

    mounted() {
      this.localValue = Math.round(this.value * this.multiplication * 100) / 100
    },

    components: {},

    watch: {
      value(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.localValue = Math.round(newVal * this.multiplication * 100) / 100
        }
      },
      localValue(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('input', newVal / this.multiplication)
        }
      }
    },

    methods: {}
  }
</script>
