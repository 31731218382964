import LocationPicker from './LocationPicker.vue'
import {LocationPickerEventBus} from './LocationPickerEventBus'

const LocationPickerPlugin = {
  install (app, options) {
    if (!options.key) {
      console.error('[Vue Location Picker warn]: You should give a Google Maps API key')
      return
    }

    options.libraries = 'places'
    options.callback = 'initLocationPicker'

    // set the callback function
    window.initLocationPicker = () => {
      window.locationPickerInitialized = true
      LocationPickerEventBus.$emit('location-picker-init')
    }

    // construct the url
    var apiUrl = 'https://maps.googleapis.com/maps/api/js'
    var params = Object.keys(options).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(options[key])}`)
    var url = `${apiUrl}?${params.join('&')}`

    // create and append the script to body
    var script = document.createElement('script')
    script.src = url
    script.async = true
    script.defer = true
    document.body.appendChild(script)

    app.component('o-location-picker', LocationPicker)
  }
}

export default LocationPickerPlugin
