var moment = require('moment-timezone')

import Vue from 'vue'
import store from '~/store'

Vue.filter('formatDate', function (value) {
  if (value) {
    var user = store.getters['auth/user']
    if (!user) {
      return moment.utc(value).format(window.config.format_date)
    }
    return moment.utc(value).format(user.format_date)
  }
})

Vue.filter('formatDatetime', function (value) {
  if (value) {
    var user = store.getters['auth/user']
    if (!user) {
      return moment.utc(value).format(window.config.format_datetime)
    }
    return moment.utc(value).tz(user.timezone).format(user.format_datetime)
  }
})

Vue.filter('formatTime', function (value) {
  if (value) {
    var user = store.getters['auth/user']
    if (!user) {
      return moment.utc(value).format(window.config.format_time)
    }
    return moment.utc(value).tz(user.timezone).format(user.format_time)
  }
})

Vue.filter('formatTimeString', function (value) {
  if (value) {
    var user = store.getters['auth/user']
    if (!user) {
      return moment.utc("2019-11-07" + ' ' + value).format(window.config.format_time)
    }
    return moment.utc("2019-11-07" + ' ' + value).tz(user.timezone).format(user.format_time)
  }
})
