<template>
    <b-tabs>
        <b-tab :title="$t('core.server_side_translations')" active>
            <o-card>
                <b-row>
                    <b-col>
                        <o-value-select :label="$t('core.module')" v-model="serverModule"
                                        url="/api/system/translations/server-modules"></o-value-select>
                    </b-col>
                    <b-col>
                        <o-value-select :label="$t('core.source_language')" :items="locales"
                                        v-model="sourceLanguage"></o-value-select>
                    </b-col>
                    <b-col>
                        <o-value-select :label="$t('core.target_language')" :items="locales"
                                        v-model="targetLanguage"></o-value-select>
                    </b-col>
                    <b-col cols="auto" class="mt-auto mb-3">
                        <o-button :loading="loading" @click="loadServerSideTranslations">{{$t('core.load')}}</o-button>
                    </b-col>
                </b-row>

                <template v-if="serverTranslations">
                    <div class="table-responsive">
                        <table class="table table-sm">
                            <thead>
                            <tr class="d-flex">
                                <th class="col-2">{{$t('core.translation_key')}}</th>
                                <th class="col-5">{{$t('core.translation_source')}}</th>
                                <th class="col-5">{{$t('core.translation_target')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(sourceTranslation, sourceTranslationKey) in serverTranslations.source_translations"
                                class="d-flex">
                                <td class="col-2">{{sourceTranslationKey}}</td>
                                <td class="col-5 translation-source">{{sourceTranslation}}</td>
                                <td class="col-5">
                                    <template v-if="/\r|\n/.exec(sourceTranslation)">
                                        <o-textarea-control
                                                v-model="serverTranslations.target_translations[sourceTranslationKey]"></o-textarea-control>
                                    </template>
                                    <template v-else>
                                        <o-input-control
                                                v-model="serverTranslations.target_translations[sourceTranslationKey]"></o-input-control>
                                    </template>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </o-card>
            <o-action-toolbar>
                <o-button @click="saveServerSideTranslations" :loading="saving" class="ml-auto">{{$t('core.save')}}
                </o-button>
            </o-action-toolbar>
        </b-tab>
        <b-tab :title="$t('core.client_side_translations')" @click="$refs.clientSideTranslationToolbar.setHeight()">
            <o-card>
                <b-row>
                    <b-col>
                        <o-value-select :label="$t('core.module')" v-model="clientModule"
                                        url="/api/system/translations/client-modules"></o-value-select>
                    </b-col>
                    <b-col>
                        <o-value-select :label="$t('core.source_language')" :items="locales"
                                        v-model="sourceLanguage"></o-value-select>
                    </b-col>
                    <b-col>
                        <o-value-select :label="$t('core.target_language')" :items="locales"
                                        v-model="targetLanguage"></o-value-select>
                    </b-col>
                    <b-col cols="auto" class="mt-auto mb-3">
                        <o-button :loading="loading" @click="loadClientSideTranslations">{{$t('core.load')}}</o-button>
                    </b-col>
                </b-row>

                <template v-if="clientTranslations">
                    <div class="table-responsive">
                        <table class="table table-sm">
                            <thead>
                            <tr class="d-flex">
                                <th class="col-2">{{$t('core.translation_key')}}</th>
                                <th class="col-5">{{$t('core.translation_source')}}</th>
                                <th class="col-5">{{$t('core.translation_target')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(sourceTranslation, sourceTranslationKey) in clientTranslations.source_translations"
                                class="d-flex">
                                <td class="col-2">{{sourceTranslationKey}}</td>
                                <td class="col-5 translation-source">{{sourceTranslation}}</td>
                                <td class="col-5">
                                    <template v-if="/\r|\n/.exec(sourceTranslation)">
                                        <o-textarea-control
                                                v-model="clientTranslations.target_translations[sourceTranslationKey]"></o-textarea-control>
                                    </template>
                                    <template v-else>
                                        <o-input-control
                                                v-model="clientTranslations.target_translations[sourceTranslationKey]"></o-input-control>
                                    </template>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </o-card>
            <o-action-toolbar ref="clientSideTranslationToolbar">
                <o-button @click="saveClientSideTranslations" :loading="saving" class="ml-auto">{{$t('core.save')}}
                </o-button>
            </o-action-toolbar>
        </b-tab>
    </b-tabs>
</template>

<script>
  import {mapGetters} from 'vuex'
  import axios from 'axios'

  export default {
    data: () => ({
      sourceLanguage: null,
      targetLanguage: null,
      serverModule: null,
      clientModule: null,
      loading: false,
      saving: false,
      clientTranslations: null,
      serverTranslations: null
    }),

    methods: {
      loadClientSideTranslations() {
        this.loading = true
        axios.get('/api/system/translations/client-translations', {
          params: {
            module: this.clientModule,
            sourceLanguage: this.sourceLanguage,
            targetLanguage: this.targetLanguage
          }
        }).then((response) => {
          this.loading = false
          this.clientTranslations = response.data
        }).catch((error) => {
          this.loading = false
          this.$notify({
            title: this.$i18n.t('core.error'),
            text: this.$i18n.t('core.error_text', {error: error}),
            type: 'error'
          })
        })
      },
      saveClientSideTranslations() {
        this.saving = true
        axios.post('/api/system/translations/client-translations', {
          module: this.clientModule,
          language: this.targetLanguage,
          translations: this.clientTranslations.target_translations
        }).then((response) => {
          this.saving = false
          this.$notify({
            title: this.$i18n.t('core.client_translations_saved'),
            text: this.$i18n.t('core.client_translations_saved_text', {count: response.data.count}),
          })
        }).catch((error) => {
          this.saving = false
          this.$notify({
            title: this.$i18n.t('core.error'),
            text: this.$i18n.t('core.error_text', {error: error}),
            type: 'error'
          })
        })
      },

      loadServerSideTranslations() {
        this.loading = true
        axios.get('/api/system/translations/server-translations', {
          params: {
            module: this.serverModule,
            sourceLanguage: this.sourceLanguage,
            targetLanguage: this.targetLanguage
          }
        }).then((response) => {
          this.loading = false
          this.serverTranslations = response.data
        }).catch((error) => {
          this.loading = false
          this.$notify({
            title: this.$i18n.t('core.error'),
            text: this.$i18n.t('core.error_text', {error: error}),
            type: 'error'
          })
        })
      },
      saveServerSideTranslations() {
        this.saving = true
        axios.post('/api/system/translations/server-translations', {
          module: this.serverModule,
          language: this.targetLanguage,
          translations: this.serverTranslations.target_translations
        }).then((response) => {
          this.saving = false
          this.$notify({
            title: this.$i18n.t('core.server_translations_saved'),
            text: this.$i18n.t('core.server_translations_saved_text', {count: response.data.count}),
          })
        }).catch((error) => {
          this.saving = false
          this.$notify({
            title: this.$i18n.t('core.error'),
            text: this.$i18n.t('core.error_text', {error: error}),
            type: 'error'
          })
        })
      }
    },

    computed: mapGetters({
      locales: 'lang/locales'
    })

  }
</script>

<style scoped>
    .translation-source {
        white-space: pre-wrap;
    }
</style>