<template>
  <th @click="sortColumn">
    <slot></slot>
    <template v-if="isSortable">
      <fa :icon="sortableIcon" v-if="isSorted"></fa>
    </template>
  </th>
</template>

<script>
  import parentFinder from '../plugins/parent-finder'

  export default {
    name: 'o-table-header',
    mixins: [parentFinder],
    props: {
      sortable: {
        type: Boolean,
        default: true
      },
      column: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      sortDirection: ''
    }),
    methods: {
      sortColumn() {
        var table = this.findParentComponent('o-table')
        if (table === null) {
          this.$notify({
            title: this.$i18n.t('core.parent_component_not_found'),
            text: this.$i18n.t('core.parent_component_not_found_text'),
            type: 'error'
          })
        }

        // inform our table component to sort by a new column, it will later call setSortProperties to update the
        // component properties to show an asc/desc arrow
        table.$emit('sort-column', {column: this.column, direction: this.sortDirection === 'asc' ? 'desc' : 'asc'})
      },
      clearSort() {
        this.sortDirection = ''
      },
      setSortProperties(sortBy, sortByDirection) {
        if (sortBy === this.column) {
          this.sortDirection = sortByDirection
        } else {
          this.sortDirection = ''
        }
      }
    },
    computed: {
      isSortable() {
        return this.sortable && this.column !== ''
      },
      sortableIcon() {
        return this.sortDirection === 'asc' ? 'arrow-down' : 'arrow-up'
      },
      isSorted() {
        return this.sortDirection !== ''
      }
    }
  }
</script>
