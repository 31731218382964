<template>
  <div class="row">
    <div class="col-md-3">
      <o-card :title="$t('core.settings')" class="settings-card">
        <ul class="nav flex-column nav-pills">
          <li v-for="tab in tabs" class="nav-item">
            <router-link :to="{ name: tab.route }" class="nav-link" active-class="active">
              {{ tab.name }}
            </router-link>
          </li>
        </ul>
      </o-card>
    </div>

    <div class="col-md-9">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  loading: false,

  computed: {
    tabs () {
      return [
        {
          name: this.$t('core.profile'),
          route: 'settings.profile'
        },
        {
          name: this.$t('core.password'),
          route: 'settings.password'
        }
      ]
    }
  }
}
</script>

<style>
.settings-card .card-body {
  padding: 0;
  overflow: hidden;
}
</style>
