<template>
    <button @click="login" type="button"  class="btn btn-dark">
        <slot></slot>
    </button>
</template>
<script>
export default {
    name: 'o-mysandoz-login',
    props: {
    },
    mounted () {
        window.addEventListener('message', this.onMessage, false)
    },
    beforeDestroy () {
        window.removeEventListener('message', this.onMessage)
    },
    methods: {
        async login () {
            this.openWindow('https://mysandoz.ch/token-auth/?callback=' + window.location.protocol + '//' + window.location.host+'/validate-token', this.$t('core.login'))
        },

        /**
         * @param  {Object} options
         * @return {Window}
         */
        openWindow (url, title, options = {}) {
            if (typeof url === 'object') {
                options = url
                url = ''
            }
            options = { url, title, width: 600, height: 720, ...options }
            const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left
            const dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top
            const width = window.innerWidth || document.documentElement.clientWidth || screen.width
            const height = window.innerHeight || document.documentElement.clientHeight || screen.height
            options.left = ((width / 2) - (options.width / 2)) + dualScreenLeft
            options.top = ((height / 2) - (options.height / 2)) + dualScreenTop
            const optionsStr = Object.keys(options).reduce((acc, key) => {
                acc.push(`${key}=${options[key]}`)
                return acc
            }, []).join(',')
            const newWindow = window.open(url, title, optionsStr)
            if (window.focus) {
                newWindow.focus()
            }
            return newWindow
        },

        /**
         * @param {MessageEvent} e
         */
        onMessage (e) {
            if (e.origin !== window.origin || !e.data.token) {
                return
            }
            this.$store.dispatch('auth/saveToken', {
                token: e.data.token
            })
            this.$store.dispatch('auth/fetchUser')
            this.$emit('login-successful')
        }
    }
}
</script>
