<template>
  <div :class="containerClasses">
    <b-form-select v-model="value" :options="selectItems" :value-field="valueField"
                   :text-field="textField" @change="enableFilter"></b-form-select>
  </div>
</template>

<script>
  import axios from 'axios'
  import qs from 'qs'

  export default {
    name: 'o-filter-select',
    props: {
      title: {
        type: String,
        required: true
      },
      column: {
        type: String,
        required: false
      },
      valueField: {
        type: String,
        default: 'value'
      },
      textField: {
        type: String,
        default: 'text'
      },
      url: {
        type: String,
        default: ''
      },
      classes: {
        type: String,
        default: ''
      },
      items: {
      }
    },

    data: () => ({
      containerClasses: '',
      ajaxItems: [],
      value: ''
    }),

    methods: {
      enableFilter() {
        this.$nextTick(() => {
          this.$parent.$emit('enable-filter', {
            column: this.column ? this.column : this.title,
            title: this.title,
            value: this.value,
            displayValue: this.selectItems.find((item) => item[this.valueField] == this.value)[this.textField]
          })
          this.value = ''
        })
      },

      checkUrlFilter() {
        // check url hash to see if we have a filter in the uri
        if (!document.location.search) {
          return
        }
        var params = qs.parse(document.location.search.substr(1))

        if (this.column in params.filter) {
          this.$parent.$emit('enable-filter', {
            column: this.column ? this.column : this.title,
            title: this.title,
            value: params.filter[this.column],
            displayValue: this.selectItems.find((item) => item[this.valueField] == params.filter[this.column])[this.textField]
          })
        }
      }
    },

    created() {
      if (this.url === '') {
        this.checkUrlFilter()
        return
      }
      axios
        .get(this.url, {params: {perPage: 0}})
        .then((response) => {
          this.ajaxItems = response.data.data
          this.checkUrlFilter()
        })
        .catch((error) => {
          console.log(error)
          window.alert('could not fetch value list options from ' + this.url)
        })
    },

    computed: {
      selectItems () {
        if (this.ajaxItems.length > 0) {
          return this.ajaxItems
        } else {
          return this.items
        }
      }
    },

    mounted() {
      this.$on('set-classes', (classes) => {
        this.containerClasses = classes
      })
    }
  }
</script>
