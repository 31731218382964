<template>
  <div class="input-group">
    <vue-timepicker v-model="localValue" ref="timepicker" :format="customFormatter()" hide-clear-button close-on-complete></vue-timepicker>
    <div class="input-group-append">
      <button class="btn btn-outline-secondary" type="button" @click="$refs.timepicker.toggleDropdown()">
        <fa icon="calendar"></fa>
      </button>
    </div>
  </div>
</template>

<style>
  .display-time {
    display: block;
    width: 100% !important;
    height: calc(1.5em + 0.75rem + 2px) !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da !important;
    border-radius: 0.125rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
</style>
<script>
  import VueTimepicker from 'vue2-timepicker'
  import 'vue2-timepicker/dist/VueTimepicker.css'
  import moment from 'moment'
  import store from '~/store'

  export default {
    name: 'o-timepicker-control',
    props: {
      value: {
        default: null
      },
      type: {
        type: String,
        default: 'text'
      }
    },

    data: () => ({
      localValue: '',
    }),

    mounted() {
      this.localValue = this.value
    },

    computed: {
    },

    methods: {
      customFormatter(time) {
        if (time) {
          let user = store.getters['auth/user']
          if(user) {
            return moment('2019-09-23 ' + time).format(user.format_time)
          } else {
            return moment('2019-09-23 ' + time).format('HH:mm')
          }
        }
        return null
      }
    },

    components: {
      VueTimepicker
    },

    watch: {
      value(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal === null) {
            this.localValue = ''
          } else {
            this.localValue = newVal
          }
        }
      },
      localValue(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('input', newVal ? moment('2019-09-23 ' + newVal).format('HH:mm') : null)
        }
      }
    }
  }
</script>
