<template>
  <b-dropdown :text="locale" :variant="variant" :right="right">
    <b-dropdown-item v-for="(value, key) in locales" @click.prevent="setLocale(key)" :key="key">
      {{value}}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
  import {mapGetters} from 'vuex'
  import {setLocale} from '~/plugins/i18n'
  import axios from 'axios'

  export default {
    name: 'o-locale-dropdown',

    props: {
      right: {
        type: Boolean,
        default: false
      },
      variant: {
        type: String,
        default: 'primary'
      }
    },

    computed: mapGetters({
      locale: 'lang/locale',
      locales: 'lang/locales',
      user: 'auth/user'
    }),

    methods: {
      async setLocale (locale) {
        if (this.$i18n.locale !== locale) {
          if (this.user !== null) {
            this.user['language'] = locale
            axios.post('/api/system/update-locale', this.user)
          }
          setLocale(locale)
          this.$store.dispatch('lang/setLocale', {locale})
        }
      }
    }
  }
</script>
