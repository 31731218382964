<template>
  <o-table :title="$t('offer.orders')" url="/api/cart-orders?include=user" ref="tableList" :enable-new="false" :enable-delete="false" default-sort-by-direction="desc">
    <template slot="filter">
        <o-filter v-on:filter-updated="(filters) => $refs.tableList.setFilters(filters)">
            <o-filter-select value-field="id" text-field="name" :items="users" :title="$t('offer.user')" column="user_id"></o-filter-select>
            <o-filter-datepicker :title="$t('offer.date')" column="created_at"></o-filter-datepicker>
        </o-filter>
    </template> 
    <template slot="head">
      <o-table-header column="id">{{$t('offer.id')}}</o-table-header>
      <o-table-header column="name">{{$t('offer.name')}}</o-table-header>
      <o-table-header column="user">{{$t('offer.user')}}</o-table-header>
      <o-table-header column="order_total" class="text-right">{{$t('offer.order_total')}}</o-table-header>
      <o-table-header column="created_at">{{$t('offer.created_at')}}</o-table-header>
    </template>
    <template slot="body" slot-scope="props">
      <td>{{ props.item.id }}</td>
      <td>{{ props.item.name }}</td>
      <td>{{ props.item.user ? props.item.user.data.name : '' }}</td>
      <td class="text-right">{{ props.item.order_total }}</td>
      <td>{{ props.item.created_at | formatDate }}</td>
    </template>
  </o-table>
</template>

<script>
import {mapGetters} from "vuex"
import axios from "axios"

export default {
  data() {
    return {
      users: [
        {id: 1, name: 't'}
      ]
    }
  },
  methods: {
    loadSalesRepUsers() {
      axios.get('/api/cart-orders/users').then((response) => {
        this.users = response.data
      })
    }
  },
  mounted() {
    this.loadSalesRepUsers()
  },
  created() {
  },
  computed: mapGetters({
    locale: 'lang/locale',
    user: 'auth/user'
  }),
}
</script>