export default [
    {
        title: 'offer.offerlist',
        requiredPermissions: [
            'GET api/offers',
            'POST api/offers/{id}',
            'DELETE api/offers/{id}'
        ],
        target: 'offers.list'
    },
    {
        title: 'offer.products',
        requiredPermissions: [
            'GET api/products',
        ],
        target: 'offers.product_list'
    },
    {
        title: 'offer.wholesalers',
        requiredPermissions: [
            'GET api/cart-wholesalers',
        ],
        target: 'offers.wholesaler_list'
    },
    {
        title: 'offer.reminderslist',
        requiredPermissions: [
            'GET api/reminders',
        ],
        target: 'offers.reminderlist'
    },
    {
        title: 'offer.orders',
        requiredPermissions: [
            'GET api/cart-orders',
        ],
        target: 'offers.order_list'
    },
    {
        title: 'core.system',
        children: [
            {
                title: 'core.users',
                requiredPermissions: [
                    'POST api/system/users/{id}',
                    'DELETE api/system/users/{id}'
                ],
                target: 'system.user_list'
            },
            {
                title: 'core.roles',
                requiredPermissions: [
                    'POST api/system/roles/{id}',
                    'DELETE api/system/roles/{id}'
                ],
                target: 'system.role_list'
            },
            {
                title: 'core.permissions',
                requiredPermissions: [
                    'POST api/system/permissions/{id}',
                    'DELETE api/system/permissions/{id}'
                ],
                target: 'system.permission_list'
            },
            {
                title: 'core.translations',
                requiredPermissions: [
                    'POST api/system/translations/client-translations',
                    'POST api/system/translations/server-translations'
                ],
                target: 'system.translation_list'
            }
        ]
    }
]
