<template>
  <ul :class="classes">
    <template v-for="(item, itemKey) in navItems">
      <template v-if="hasPermission(item)">
        <template v-if="'children' in item">
          <template v-if="hasVisibleChildren(item)">
            <li :class="liClasses">
              <a v-b-toggle="'child-' + itemKey" class="nav-link collapsed dropdown-toggle" href="javascript:void(0);">{{$t(item.title)}}</a>
              <b-collapse :id="'child-' + itemKey">
                <o-sidebar-children :nav-items="item.children"
                                    :classes="childClasses"
                                    :li-classes="liClasses"
                                    :level="level + 1"
                ></o-sidebar-children>
              </b-collapse>
            </li>
          </template>
        </template>
        <template v-else>
          <template v-if="level === 0">
            <li :class="liClasses">
              <router-link :to="{ name: item.target }" class="nav-link">
                {{$t(item.title)}}
              </router-link>
            </li>
          </template>
          <template v-else>
            <router-link :to="{ name: item.target }" class="dropdown-item">
              {{$t(item.title)}}
            </router-link>
          </template>
        </template>
      </template>
    </template>
  </ul>

</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'o-sidebar-children',
    props: {
      navItems: {
        type: Array,
        required: true
      },
      classes: {
        type: String,
        required: false,
        default: ''
      },
      childClasses: {
        type: String,
        required: false,
        default: ''
      },
      liClasses: {
        type: String,
        required: false,
        default: ''
      },
      level: {
        type: Number,
        default: 0
      }
    },

    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },

    methods: {
      hasVisibleChildren(item) {
        var user = this.$store.getters['auth/user']
        if (!('children' in item)) {
          return false
        } else {
          if (user.is_super_user && item.children.length > 0) {
            return true
          } else {
            for (var i = 0; i < item.children.length; i++) {
              if (this.hasPermission(item.children[i])) {
                return true
              }
            }
            return false
          }
        }
      },
      hasPermission(item) {
        var result = true
        if (!('requiredPermissions' in item)) {
          result = true
        }
        var permissions = item.requiredPermissions
        var user = this.$store.getters['auth/user']
        if (!user.is_super_user && permissions) {
          permissions.forEach((permission) => {
            if (!user.routePermissions.includes(permission)) {
              result = false
            }
          })
        }
        return result
      }
    }
  }
</script>
