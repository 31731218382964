<template>
  <b-button @click="click" :type="nativeType" :variant="variant" :disabled="loading" class="btn" :class="{
    'btn-block': block,
    'btn-loading': loading
  }">
    <slot></slot>
  </b-button>
</template>

<script>
  export default {
    name: 'o-button',

    props: {
      variant: {
        type: String,
        default: 'primary'
      },

      nativeType: {
        type: String,
        default: 'submit'
      },

      loading: {
        type: Boolean,
        default: false
      },

      block: Boolean
    },
    methods: {
      click(e) {
        this.$emit('click', e)
      }
    }
  }
</script>
