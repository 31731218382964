import * as types from '../mutation-types'

// state
export const state = {
  navItems: []
}

// getters
export const getters = {
  navItems: state => state.navItems
}

// mutations
export const mutations = {
  [types.SET_NAV_ITEMS](state, {navItems}) {
    state.navItems = navItems
  }
}

// actions
export const actions = {
  setNavItems({commit, dispatch}, {navItems}) {
    commit(types.SET_NAV_ITEMS, {navItems})
  }
}
