<template>
  <div class="default-layout flex-center position-ref full-height">
    <o-child/>
  </div>
</template>

<script>
export default {
  name: 'default-layout'
}
</script>

