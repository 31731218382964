export default [
  // Public routes
  {path: '/', name: 'welcome', component: require('~/pages/welcome').default},
  {path: '/contact', name: 'contact', component: require('~/pages/legal').default},
  {path: '/terms_of_use', name: 'terms_of_use', component: require('~/pages/legal').default},
  {path: '/privacy_policy', name: 'privacy_policy', component: require('~/pages/legal').default},
  {path: '/about_cookies', name: 'about_cookies', component: require('~/pages/legal').default},
  {path: '/validate-token', name: 'validate_token', component: require('~/pages/validate-token').default},
  // Authenticated routes.
  ...middleware('auth', [    
    {path: '/home', name: 'home', component: require('~/pages/home').default},
    {
      path: '/settings', component: require('~/pages/settings/index').default,
      children: [
        {path: '', redirect: {name: 'settings.profile'}},
        {path: 'profile', name: 'settings.profile', component: require('~/pages/settings/profile').default},
        {path: 'password', name: 'settings.password', component: require('~/pages/settings/password').default}
      ]
    },
    {
      path: '/offers', component: require('~/pages/offers/index').default,
      children: [
        {path: '', redirect: {name: 'offers.list'}},
        {path: 'list', name: 'offers.list', component: require('~/pages/offers/List').default},
        {path: 'list/:id', name: 'offers.details', component: require('~/pages/offers/Details').default},
      ]
    },
    { path: '/products', name: 'offers.product_list', component: require('~/pages/products/List').default },
    { path: '/products/:id', name: 'offers.product_detail', component: require('~/pages/products/Detail').default },
    { path: '/orders', name: 'offers.order_list', component: require('~/pages/orders/List').default },
    { path: '/orders/:id', name: 'offers.order_detail', component: require('~/pages/orders/Detail').default },
    { path: '/wholesalers', name: 'offers.wholesaler_list', component: require('~/pages/wholesalers/List').default },
    { path: '/wholesalers/:id', name: 'offers.wholesaler_detail', component: require('~/pages/wholesalers/Detail').default },
    {
      path: '/reminders', component: require('~/pages/reminders/index').default,
      children: [
        {path: '', redirect: {name: 'offers.reminderlist'}},
        {path: 'list', name: 'offers.reminderlist', component: require('~/pages/reminders/List').default},
      ]
    },
    {
      path: '/system', component: require('~/pages/system/index').default,
      children: [
        {path: 'users', name: 'system.user_list', component: require('~/pages/system/UserList').default},
        {path: 'users/:id', name: 'system.user_detail', component: require('~/pages/system/UserDetail').default},
        {path: 'roles', name: 'system.role_list', component: require('~/pages/system/RoleList').default},
        {path: 'roles/:id', name: 'system.role_detail', component: require('~/pages/system/RoleDetail').default},
        {path: 'permissions', name: 'system.permission_list', component: require('~/pages/system/PermissionList').default},
        {path: 'permissions/:id', name: 'system.permission_detail', component: require('~/pages/system/PermissionDetail').default},
        {path: 'translations', name: 'system.translation_list', component: require('~/pages/system/TranslationList').default}
      ]
    },
    // ...middleware('admin', [
    //   { path: '/admin', name: 'admin', component: require('~/pages/admin') }
    // ])
    // { path: '/example', name: 'example', component: require('~/pages/example'), middleware: ['admin'] },
  ]),

  // Guest routes.
  ...middleware('guest', [
    {path: '/login', name: 'login', component: require('~/pages/auth/login').default},
    {path: '/register', name: 'register', component: require('~/pages/auth/register').default},
    {path: '/password/reset', name: 'password.request', component: require('~/pages/auth/password/email').default},
    {path: '/password/reset/:token', name: 'password.reset', component: require('~/pages/auth/password/reset').default}
  ]),

  {path: '/*', component: require('~/pages/errors/404.vue').default}
]

/**
 * @param  {String|Function} middleware
 * @param  {Array} routes
 * @return {Array}
 */
function middleware(middleware, routes) {
  routes.forEach(route =>
    (route.middleware || (route.middleware = [])).unshift(middleware)
  )

  return routes
}
