<template>
  <nav class="navbar navbar-expand-md fixed-top navbar-light bg-white">
    <div class="container-fluid p-0">
      <router-link :to="{ name: 'home' }" class="navbar-brand">
        {{ appName }}
      </router-link>

      <div class="collapse navbar-collapse" id="navbarToggler">
        <div class="navbar-nav ml-auto mt-2 mt-lg-0">
          <locale-dropdown/>
        </div>
        <ul class="navbar-nav">
          <template v-if="!user">
            <li class="nav-item">
              <router-link :to="{ name: 'login' }" class="nav-link" active-class="active">
                {{ $t('core.login') }}
              </router-link>
            </li>
          </template>
        </ul>
      </div>
      <div class="d-md-none">
        <fa icon="bars" size="lg" @click="toggleSidebar"/>
      </div>
    </div>
  </nav>
</template>

<script>
  import {mapGetters} from 'vuex'
  import LocaleDropdown from './LocaleDropdown'
  import FontAwesomeIcon from '@fortawesome/vue-fontawesome'

  export default {
    name: 'o-navbar',
    data: () => ({
      appName: window.config.appName
    }),

    computed: mapGetters({
      user: 'auth/user'
    }),

    components: {
      LocaleDropdown,
      FontAwesomeIcon
    },

    methods: {
      async logout() {
        // Log out the user.
        await this.$store.dispatch('auth/logout')

        // Redirect to login.
        this.$router.push({name: 'login'})
      },
      toggleSidebar() {
        this.$emit('toggle-sidebar')
      }
    }
  }
</script>
