<template>
  <o-form-group :label="label" :error-field="errorField" :class="{'has-error': errorField in $root.errors}">
    <o-input-control v-model="localValue" :type="type" :placeholder="placeholder" :readonly="readonly" :min="min" :max="max" :pattern="pattern" :onkeyup="onkeyup" :onblur="onblur" :oninput="oninput"></o-input-control>
  </o-form-group>
</template>

<script>
export default {
  name: 'o-input',
  props: {
    value: {
      default: null
    },
    label: {
      type: String
    },
    type: {
      type: String,
      default: 'text'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    errorField: {
      type: String
    },
    placeholder: {
      type: String
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    pattern: {
      type: String
    },
    onkeyup: {
      type: String
    },
    onblur: {
      type: String
    },
    oninput: {
      type: String
    }
  },

  data: () => ({
    localValue: ''
  }),

  mounted () {
    this.localValue = this.value
  },

  watch: {
    value (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localValue = newVal
      }
    },
    localValue (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('input', newVal)
      }
    }
  },

  methods: {}
}
</script>
