<template>
  <div id="app">
    <loading ref="loading"></loading>

    <transition mode="out-in" name="page">
      <component :is="layout" v-if="layout"></component>
    </transition>

    <notifications classes="ortic-notifications"/>
  </div>
</template>

<script>
  import Loading from './Loading'

  // Load layout components dynamically.
  const requireContext = require.context('../layouts', false, /.*\.vue$/)

  const layouts = requireContext.keys()
    .map(file =>
      [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
    )
    .reduce((components, [name, component]) => {
      components[name] = component.default || component
      return components
    }, {})

  export default {
    el: '#app',

    components: {
      Loading
    },

    data: () => ({
      layout: null,
      defaultLayout: 'app',
      errors: {}
    }),

    mounted() {
      this.$loading = this.$refs.loading
    },

    methods: {
      /**
       * Set the application layout.
       *
       * @param {String} layout
       */
      setLayout(layout) {
        if (!layout || !layouts[layout]) {
          layout = this.defaultLayout
        }

        this.layout = layouts[layout]
      }
    }
  }
</script>
