import Vue from 'vue'

Vue.filter('formatNumber', (val) => {
    var ret = new Intl.NumberFormat('de-CH', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(val)
    ret = ret.replace("\u2019", "'")
    return ret
})
Vue.filter('formatInteger', (val) => {
    var ret = new Intl.NumberFormat('de-CH', {minimumFractionDigits: 0}).format(Math.round(val))
    ret = ret.replace("\u2019", "'")
    return ret
})
