<template>
  <o-form :title="$t('offer.product')"
          :url="`/api/products/${$route.params.id}`"
          class="ortic-form-horizontal">
    <template slot-scope="props">
      <b-row>
        <b-col>
          <o-input :label="$t('offer.product')" :errorField="'product'" v-model="props.item.product"/>
          <o-input :label="$t('offer.package')" :errorField="'package'" v-model="props.item.package"/>
          <o-input :label="$t('offer.gtin')" :errorField="'gtin'" v-model="props.item.gtin"/>
          <o-input :label="$t('offer.pharma_code')" :errorField="'pharma_code'" v-model="props.item.pharma_code"/>
          <o-input :label="$t('offer.material_no')" :errorField="'material_no'" v-model="props.item.material_no"/>
          <o-input :label="$t('offer.molecule')" :errorField="'molecule'" v-model="props.item.molecule"/>
          <o-input :label="$t('offer.swissmedic_list')" :errorField="'swissmedic_list'" v-model="props.item.swissmedic_list"/>
          <o-input :label="$t('offer.efp')" type="number" step="0.01" :errorField="'efp'" v-model="props.item.efp"/>
        </b-col>
      </b-row>
    </template> 
  </o-form>
</template>

<script>
  export default {
    data: () => ({
    }),

    mounted () {
    },

    methods: {
    }
  }
</script>
