<template>
  <o-table :title="$t('offer.wholesalers')" url="/api/cart-wholesalers" ref="tableList">
    <template slot="filter">       
    </template>  
    <template slot="head">
      <o-table-header column="wholesaler">{{$t('offer.wholesaler')}}</o-table-header>
      <o-table-header column="recipient_email">{{$t('offer.recipient_email')}}</o-table-header>
    </template>
    <template slot="body" slot-scope="props">
      <td>{{ props.item.wholesaler }}</td>
      <td>{{ props.item.recipient_email }}</td>
    </template>
  </o-table>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  data() {
    return {
    }
  },
  methods: {
  },
  created() {
  },
  computed: mapGetters({
    locale: 'lang/locale',
    user: 'auth/user'
  }),
}
</script>