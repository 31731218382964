<template>
  <div class="card">
    <div v-if="title" class="card-header px-0 pt-0 pb-3 row">
      <div class="col text-left">{{ title }}</div>
      <div class="col text-right" v-if="enableBack">
        <o-button variant="light" @click.prevent="back">{{$t('core.back')}}</o-button>
      </div>
    </div>

    <div class="card-body" :class="{'no-shadow': !shadow}">
      <slot></slot>
    </div>

    <slot name="footer"></slot>
  </div>
</template>

<script>
  export default {
    name: 'o-card',
    props: {
      title: {
        default: null
      },
      syncPageTitle: {
        type: Boolean,
        default: true
      },
      shadow: {
        type: Boolean,
        default: true
      },
      enableBack: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      updatePageTitle() {
        if (!this.syncPageTitle || this.title === '') {
          return
        }
        if (this.title) {
          document.title = this.title + ' – ' + window.config.appName
        }
      },
      back() {
        this.$router.back()
      },
    },
    watch: {
      title() {
        this.updatePageTitle()
      }
    },
    mounted() {
      this.updatePageTitle()
    }
  }
</script>
