<template>
  <o-form-group :label="label" :error-field="errorField" :class="{'has-error': errorField in $root.errors}">
    <o-datepicker-control v-model="localValue" :typeable="typeable" :readonly="readonly" :disabled="disabled" :open-date="openDate" :disabled-dates="disabledDates"></o-datepicker-control>
  </o-form-group>
</template>

<script>
  export default {
    name: 'o-datepicker',
    props: {
      value: {
        default: null
      },
      label: {
        type: String
      },
      typeable: {
        type: Boolean,
        default: false
      },
      errorField: {
        type: String
      },
      readonly: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      disabledDates : {
        type: Object
      },
      openDate : {
        type: Date
      }
    },

    data: () => ({
      localValue: ''
    }),

    mounted() {
      this.localValue = this.value
    },

    watch: {
      value(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.localValue = newVal
        }
      },
      localValue(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('input', newVal)
        }
      }
    },

    methods: {}
  }
</script>
