import axios from 'axios'
import store from '~/store'
import router from '~/router'
import { i18n } from '~/plugins/i18n'
import Vue from 'vue'

// Request interceptor
axios.interceptors.request.use(request => {
  const token = store.getters['auth/token']
  if (token) {
    request.headers.common['Authorization'] = `Bearer ${token}`
  }

  const locale = store.getters['lang/locale']
  if (locale) {
    axios.defaults.headers.common['Accept-Language'] = locale
  }

  // request.headers['X-Socket-Id'] = Echo.socketId()

  return request
})

// Response interceptor
axios.interceptors.response.use(response => response, error => {
  const { status } = error.response

  if (status === 405) {
    Vue.prototype.$notify({
      title: i18n.t('core.invalid_url'),
      text: i18n.t('core.invalid_url_text'),
      type: 'warn'
    })

    router.push({ name: 'welcome' })
  }

  if (status === 401 && store.getters['auth/check']) {
    Vue.prototype.$notify({
      title: i18n.t('core.token_expired_alert_title'),
      text: i18n.t('core.token_expired_alert_text'),
      type: 'warn'
    })

    store.dispatch('auth/logout')

    router.push({ name: 'login' })
  }

  return Promise.reject(error)
})
