<template>
  <div>
    <div class="ortic-action-toolbar-spacer" ref="spacer"></div>
    <div class="ortic-action-toolbar d-flex" ref="toolbar">
      <div class="d-none d-md-block brand-indent"></div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'o-action-toolbar',
    mounted() {
      this.setHeight()
    },
    methods: {
      setHeight() {
        this.$nextTick(() => {
          this.$refs.spacer.setAttribute('style', 'height:' + this.$refs.toolbar.offsetHeight + 'px')
        })
      }
    }
  }
</script>
