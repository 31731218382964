<template>
  <b-tabs>
    <b-tab :title="$t('core.permissions')" active>
      <o-table ref="permissionList" url="/api/system/permissions">
        <template slot="filter">
          <o-filter v-on:filter-updated="(filters) => $refs.permissionList.setFilters(filters)">
            <o-filter-text :title="$t('core.name')" column="name"></o-filter-text>
          </o-filter>
        </template>
        <template slot="head">
          <o-table-header column="name">{{$t('core.name')}}</o-table-header>
        </template>
        <template slot="body" slot-scope="props">
          <td>{{ props.item.name }}</td>
        </template>
      </o-table>
    </b-tab>
    <b-tab :title="$t('core.route_permissions')">
      <o-table :enable-delete="false" :enable-edit="false" :enable-new="false"
               ref="routePermissionList" url="/api/system/route_permissions">
        <template slot="filter">
          <div class="d-flex">
            <div>
              <o-filter v-on:filter-updated="(filters) => $refs.routePermissionList.setFilters(filters)">
                <o-filter-text :title="$t('core.uri')" column="uri"></o-filter-text>
                <o-filter-select :items="httpMethods" :title="$t('core.method')" column="method"></o-filter-select>
                <o-filter-text :title="$t('core.action')" column="action"></o-filter-text>
              </o-filter>
            </div>
            <div class="ml-auto">
              <o-button :loading="routeScanningInProgress" @click="scanRoutes">{{$t('core.scan_routes')}}</o-button>
            </div>
          </div>
        </template>
        <template slot="head">
          <th>{{$t('core.uri')}}</th>
          <th>{{$t('core.method')}}</th>
          <th>{{$t('core.action')}}</th>
        </template>
        <template slot="body" slot-scope="props">
          <td>{{ props.item.uri }}</td>
          <td>{{ props.item.method }}</td>
          <td>{{ props.item.action }}</td>
        </template>
      </o-table>
    </b-tab>
  </b-tabs>
</template>

<script>
  import axios from 'axios'

  export default {
    data: () => ({
      routeScanningInProgress: false,
      httpMethods: [
        {value: 'GET', text: 'GET'},
        {value: 'POST', text: 'POST'},
        {value: 'HEAD', text: 'HEAD'},
        {value: 'PUT', text: 'PUT'},
        {value: 'PATCH', text: 'PATCH'},
        {value: 'DELETE', text: 'DELETE'},
        {value: 'OPTIONS', text: 'OPTIONS'},
        {value: 'TRACE', text: 'TRACE'},
        {value: 'CONNECT', text: 'CONNECT'}
      ]
    }),
    methods: {
      scanRoutes() {
        this.routeScanningInProgress = true
        axios.post('/api/system/route_permissions/refresh').then((response) => {
          this.routeScanningInProgress = false
          this.$refs.routePermissionList.search()
        }).catch((error) => {
          this.routeScanningInProgress = false
          this.$notify({
            title: this.$i18n.t('core.error'),
            text: this.$i18n.t('core.error_text', {error: error}),
            type: 'error'
          })
        })
      }
    }
  }
</script>
