<template>
  <div class="row login-page">
    <div class="col-lg-8 m-auto">
      <o-card :title="$t('core.login')">
        <b-row>
          <b-col md="4" class="pb-4 justify-content-center align-self-center">
            <!-- SwissRx Login Button -->
            <o-oauth-button provider="swissrx" @login-successful="redirectHome">
              {{ $t('core.login_with') }} Swiss-Rx
            </o-oauth-button>

            <o-mysandoz-login>
              {{ $t('core.login_with') }} mysandoz.ch
            </o-mysandoz-login>

          </b-col>
          <b-col md="8">
            <form @submit.prevent="login" @keydown="form.onKeydown($event)">
              <!-- Email -->
              <div class="form-group row">
                <label class="col-md-3 col-form-label text-md-right">{{ $t('core.email') }}</label>
                <div class="col-md-9">
                  <input v-model="form.email" type="email" name="email" class="form-control"
                        :class="{ 'is-invalid': form.errors.has('email') }">
                  <has-error :form="form" field="email"></has-error>
                </div>
              </div>

              <!-- Password -->
              <div class="form-group row">
                <label class="col-md-3 col-form-label text-md-right">{{ $t('core.password') }}</label>
                <div class="col-md-9">
                  <input v-model="form.password" type="password" name="password" class="form-control"
                        :class="{ 'is-invalid': form.errors.has('password') }">
                  <has-error :form="form" field="password"></has-error>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-4 offset-md-3 d-flex">
                  <!-- Submit Button -->
                  <o-button :loading="form.busy">
                    {{ $t('core.login') }}
                  </o-button>
                </div>
                <div class="col-md-5 pt-2">
                  <router-link :to="{ name: 'password.request' }" class="float-right small">
                    {{ $t('core.forgot_password') }}
                  </router-link>
                </div>
              </div>
            </form>
          </b-col>
        </b-row>


      </o-card>
    </div>
  </div>
</template>

<style>
  .max-width-65 {
    max-width: 65%;
  }
</style>

<script>
  import Form from 'vform'
  import {setLocale} from '~/plugins/i18n'

  export default {
    data: () => ({
      form: new Form({     
        email: '',
        password: ''
      }),
      remember: true
    }),

    methods: {
      async login() {
        // Submit the form.
        const {data} = await this.form.post('/api/login')

        // Save the token.
        this.$store.dispatch('auth/saveToken', {
          token: data.token,
          remember: this.remember
        })

        // Fetch the user.
        await this.$store.dispatch('auth/fetchUser')

        let lang = this.$store.state['auth']['user']['language']
        if (lang !== null && this.$i18n.locale !== lang) {
          setLocale(lang)
          this.$store.dispatch('lang/setLocale', { 'locale': lang })
        }

        // Redirect to previous page or home in case nothing was specified
        if (this.$route.query && this.$route.query.from) {
          this.$router.push(this.$route.query.from)
        } else {
          this.$router.push({name: 'home'})
        }
      },
      redirectHome() {
        this.$router.push({name: 'welcome'})
      }
    },

    computed: {
      githubAuth: () => window.config.githubAuth,
      swissRxAuth: () => window.config.swissRxAuth
    }
  }
</script>
