<template>
  <input v-model="localValue" :type="type" class="form-control" :class="sizeClass" :readonly="readonly" :placeholder="placeholder" :min="min" :max="max" :pattern="pattern" :onkeyup="onkeyup" :onblur="onblur" :oninput="oninput">
</template>

<script>
  export default {
    name: 'o-input-control',
    props: {
      value: {
        default: null
      },
      type: {
        type: String,
        default: 'text'
      },
      readonly: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String
      },
      size: {
        type: String,
        default: ''
      },
      min: {
        type: Number
      },
      max: {
        type: Number
      },
      pattern: {
        type: String
      },
      onkeyup: {
        type: String
      },
      onblur: {
        type: String
      },
      oninput: {
        type: String
      }
    },

    data: () => ({
      localValue: ''
    }),

    mounted() {
      this.localValue = this.value
    },

    computed: {
      sizeClass() {
        if (this.size) {
          return 'form-input-size-' + this.size
        }
        return ''
      }
    },

    components: {},

    watch: {
      value(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.localValue = newVal
        }
      },
      localValue(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('input', newVal)
        }
      }
    },

    methods: {}
  }
</script>
