<template>
  <o-form-group :label="label" :error-field="errorField" :class="{'has-error': errorField in $root.errors}">
    <o-checkbox-control class="w-100" v-model="localValue" :readonly="readonly"></o-checkbox-control>
  </o-form-group>
</template>

<script>
  export default {
    name: 'o-checkbox',
    props: {
      value: {
        default: null
      },
      label: {
        type: String
      },
      readonly: {
        type: Boolean,
        default: false
      },
      errorField: {
        type: String
      }
    },

    data: () => ({
      localValue: ''
    }),

    mounted() {
      this.localValue = this.value
    },

    watch: {
      value(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.localValue = newVal
        }
      },
      localValue(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('input', newVal)
        }
      }
    },

    methods: {}
  }
</script>
